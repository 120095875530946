/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";
import { PDFDownloadLink } from "@react-pdf/renderer";

//IMGS
import { ReactComponent as Close } from "../../icons/close.svg";
import { ReactComponent as FileIcon } from "../../icons/file.svg";
import { ReactComponent as RotateIcon } from "../../icons/rotate.svg";

//COMPONENTS
import MainButton from "../Buttons/MainButton.js";
import FormatSwitchButton from "../Buttons/FormatSwitchButton.js";
import PdfExport from "./../../../views/Exports/PdfExport.js"

const StyledPrintModal = styled(NanoFlex)`
  width: 400px;
  background-color: ${(props) => props.theme.color.main.white};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden;
  .contactModalWrapper {
    .headerModal {
      position: relative;
      height: auto;
      padding: 16px;
      background-color: ${(props) => props.theme.color.main.yellow};
      h3 {
        text-transform: uppercase;
      }
      .closeBtn {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 6px;
        right: 6px;
        border-radius: 60px;
        cursor: pointer;
        svg {
          width: 11px;
          height: auto;
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
        &:hover {
          background-color: #ffffff8f;
        }
      }
    }
    .bodyModal {
      padding: 16px 32px 32px 32px;

      .formContainer {
        height: auto;
        margin: 16px 0;
        .iconFileWrapper {
          margin-bottom: 16px;
          .iconContainer {
            height: auto;
            width: 120px;
            transition: ${(props) => props.theme.transition};
            margin-bottom: 8px;
            svg {
              max-width: 100%;
              max-height: auto;
              path {
                fill: ${(props) => props.theme.color.colorsPalette.grey};
              }
            }
          }
          .rotated {
            transform: rotate(-90deg);
            transition: ${(props) => props.theme.transition};
          }

          .legendFormat {
            margin-top: 8px;
          }
        }
        .optionsWrapper {
          .itemOption {
            width: auto;
            height: auto;
            margin-right: 8px;
            user-select: none;
            .iconRotateWrapper {
              background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
              padding: 6px;
              border-radius: 60px;
              cursor: pointer;
              svg {
                width: 14px;
                height: auto;
                path {
                  fill: ${(props) => props.theme.color.colorsPalette.darkBlue};
                }
              }
              &:hover {
                opacity: 0.8;
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .bottomWrapper {
        margin-top: 16px;
        height: auto;
        a {
          text-decoration: none;
        }
      }
    }
  }
`;


export class PrintModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      formatSelected: 1,
      rotate: this.props.rotate ?? false,
    };
  }


  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  
  onCancel = () => {
    this.setState(() => {
      if (this.props.cancelAction) this.props.cancelAction();
    });
  };

  toggleRotate = () => {
    this.setState({ rotate: !this.state.rotate });
  };
  
  render() {
    return (
      <StyledPrintModal justifyContent="flex-start">
        <NanoFlex className="contactModalWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <NanoFlex className="headerModal">
            <Type.h3>
              <b>{this.translation.global.format}</b>
            </Type.h3>
            <NanoFlex className="closeBtn" onClick={this.onCancel}>
              <Close />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="bodyModal" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="formContainer" flexDirection="column">
              <NanoFlex className="iconFileWrapper" flexDirection="column">
                <NanoFlex className={`iconContainer ${this.state.rotate ? "rotated" : ""}`}>
                  <FileIcon />
                </NanoFlex>
                <NanoFlex className="legendFormat">
                  {!this.state.rotate && (
                  <Type.h6>Vertical</Type.h6>
                  )}
                  {this.state.rotate && (
                  <Type.h6>Horizontal</Type.h6>
                  )}
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="optionsWrapper">
                <NanoFlex className="itemOption" onClick={this.toggleRotate}>
                  <NanoFlex className="iconRotateWrapper">
                    <RotateIcon />
                  </NanoFlex>
                </NanoFlex>
                <FormatSwitchButton selected={this.state.formatSelected} onSelectedFormatChange={(e) => this.setState({ formatSelected: e })} />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="bottomWrapper">
              <PDFDownloadLink document={<PdfExport data={this.props.data} convert={this.props.convert} page={this.state.formatSelected === 1 ? "A4" : "A3"} orientation={this.state.rotate ? "landscape" : "portrait"} municipality={this.props.municipality} designation={this.props.designation} name={this.props.name} />} fileName={`${this.props.name}_Export.pdf`}>
                {({ blob, url, loading, error }) => (loading ? "" : <MainButton label={this.translation.global.print} />)}
              </PDFDownloadLink>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledPrintModal>
    );
  }
}
