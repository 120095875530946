import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import { NavLink } from "react-router-dom";
import Type from "../../Global/Typography.js";

//Components

//Images
import SNITLogo from "../../Global/images/snitLogo.png";
import IGTLogo from "../../Global/images/igt-vigor-e-hist.png";
import RENLogo from "../../Global/images/Ren_vigor_historico.png";

const StyledHome = styled(NanoFlex)`
  .homeContainer {
    padding: 32px;
    .logoContainer {
      height: 20vh;
      img {
        width: auto;
        height: 100%;
        object-fit: contain;
      }
    }
    .navigationContainer {
      margin-top: 32px;
      background-color: #ffffff5e;
      border-radius: 20px;
      .navItem {
        margin-right: 120px;
        .navBtnWrapper {
          cursor: pointer;
          transition: ${(props) => props.theme.transition};
          width: 200px;
          height: 200px;
          padding: 10px;
          background-color: ${(props) => props.theme.color.main.white};
          
          box-shadow: ${(props) => props.theme.boxShadow};
          img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
          
          &:hover {
            transform: scale(1.1);
            transition: ${(props) => props.theme.transition};
          }
        }
        &:last-child {
            margin-right: 0;
          }
      }
    }
  }
`;

export class Home extends Component {
  render() {
    return (
      <StyledHome>
        <NanoFlex className="homeContainer" flexDirection="column">
          <NanoFlex className="logoContainer" justifyContent="flex-start">
            <img src={SNITLogo} alt="SNIT Logo" />
          </NanoFlex>
          <NanoFlex className="navigationContainer">
            <NavLink to="/igt" className="navItem">
              <NanoFlex className="navBtnWrapper">
                <img src={IGTLogo} alt="IGT Logo" />
              </NanoFlex>
            </NavLink>

            <NavLink to="/ren" className="navItem">
              <NanoFlex className="navBtnWrapper">
                <img src={RENLogo} alt="SRUP Logo" />
              </NanoFlex>
            </NavLink>
          </NanoFlex>
        </NanoFlex>
      </StyledHome>
    );
  }
}
