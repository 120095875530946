import React from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

const StyledSimpleButton = styled(NanoFlex)`
  height: auto;
  width: auto;
  margin: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  .simpleButtonWrapper {
    cursor: pointer;
    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
    text-transform: uppercase;
    transition: all 0.4s ease-in-out;
    transition: ${(props) => props.theme.transition};
    p {
      color: ${(props) => (props.disabled ? props.theme.color.colorsPalette.lightGrey :  props.theme.color.main.font)};
    }
    &:hover {
      transition: ${(props) => props.theme.transition};
      opacity: 0.7;
    }
  }
`;

const SimpleButton = (props) => {
  return (
    <StyledSimpleButton className="simpleButton" disabled={props.disabled}>
      <NanoFlex className="simpleButtonWrapper"  onClick={() => props.clickAction(props.id)}>
        <Type.p><b>{props.label}</b></Type.p>
        </NanoFlex>    
    </StyledSimpleButton>
  );
};

// Set default props
SimpleButton.defaultProps = {
  id: 0,
  label: "",

  clickAction: () => null,
};

export default SimpleButton;
