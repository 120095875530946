/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

//IMGS
import { ReactComponent as Close } from "../../icons/close.svg";

//COMPONENTS
import Input from "../Forms/Input.js";
import Textarea from "../Forms/Textarea.js";
import MainButton from "../Buttons/MainButton.js";
import Loading from "../../../Global/Elements/Loading/Loading.js";

//Services
import userInteractionsService from "./../../../../services/UserInteractionsService";

const StyledContactModal = styled(NanoFlex)`
  width: 400px;
  background-color: ${(props) => props.theme.color.main.white};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden;
  .contactModalWrapper {
    .headerModal {
      position: relative;
      height: auto;
      padding: 16px;
      background-color: ${(props) => props.theme.color.main.yellow};
      h3 {
        text-transform: uppercase;
      }
      .closeBtn {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 6px;
        right: 6px;
        border-radius: 60px;
        cursor: pointer;
        svg {
          width: 11px;
          height: auto;
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
        &:hover {
          background-color: #ffffff8f;
        }
      }
    }
    .bodyModal {
      padding: 16px 32px 32px 32px;
      .infoContact {
        border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
        padding-bottom: 16px;
        .asideInfo {
          height: auto;
          width: auto;
          margin-right: 32px;
          .infoBlock {
            width: auto;
            height: auto;
            margin-bottom: 8px;
            a {
              text-decoration: none;
              p {
                color: ${(props) => props.theme.color.colorsPalette.lightBlue};
              }
              &:hover {
                p {
                  text-decoration: underline;
                }
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      form {
        width: 100%;
      }
      .formContainer {
        height: auto;
        margin-top: 16px;
        .formWrapper {
          width: 100%;
          margin-bottom: 8px;
          .titleForm {
            margin-bottom: 4px;
            text-transform: uppercase;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .bottomWrapper {
        margin-top: 16px;
        height: auto;
      }
    }
  }
`;

export class ContactModal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      loading: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onCancel = () => {
    this.setState(() => {
      if (this.props.cancelAction) this.props.cancelAction();
    });
  };

  onContact = () => {
    this.setState({ loading: true }, () => {
      userInteractionsService
        .sendContact({
          name: this.state.name,
          subject: this.state.subject,
          email: this.state.email,
          message: this.state.message,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
          }
        })
        .then((data) => {
          //if (data) {
            this.setState({ loading: false });
            this.props.cancelAction();
          //}
        });
    });
  };

  render() {
    let loading = this.state.loading;

    return (
      <StyledContactModal justifyContent="flex-start">
        {loading && (
          <NanoFlex className="loadingWrapper">
            <Loading />
          </NanoFlex>
        )}
        {!loading && (
          <NanoFlex className="contactModalWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="headerModal">
              <Type.h3>
                <b>{this.translation.global.contactUs}</b>
              </Type.h3>
              <NanoFlex className="closeBtn" onClick={this.onCancel}>
                <Close />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="bodyModal" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="infoContact" justifyContent="space-between" alignItems="flex-end">
                <NanoFlex className="asideInfo" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <NanoFlex className="infoBlock" justifyContent="flex-start" alignItems="flex-start">
                    <Type.p>
                      <b>Direcção-Geral do Território</b>
                    </Type.p>
                  </NanoFlex>
                  <NanoFlex className="infoBlock" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.h6>
                      <em>{this.translation.contact.address}</em>
                    </Type.h6>
                    <Type.p>Rua Artilharia Um, 107</Type.p>
                    <Type.p>1099-052 Lisboa, Portugal</Type.p>
                  </NanoFlex>
                  <NanoFlex className="infoBlock" justifyContent="flex-start" alignItems="flex-start">
                    <a href="mailto:snit@dgterritorio.pt">
                      <Type.p>
                        <b>snit@dgterritorio.pt</b>
                      </Type.p>
                    </a>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="asideInfo" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <NanoFlex className="infoBlock" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.h6>{this.translation.contact.phone}</Type.h6>
                    <Type.p>(+351) 21 381 96 00</Type.p>
                  </NanoFlex>
                  <NanoFlex className="infoBlock" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.h6>{this.translation.contact.fax}</Type.h6>
                    <Type.p>(+351) 21 381 96 99</Type.p>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              <form>
                <NanoFlex className="formContainer" flexDirection="column">
                  <NanoFlex className="formWrapper">
                    <Input title={this.translation.contact.name} placeholder={this.translation.contact.nameSurname} onChangeAction={(e) => this.setState({ name: e })} />
                  </NanoFlex>
                  <NanoFlex className="formWrapper">
                    <Input title={this.translation.contact.email} placeholder={this.translation.contact.email} onChangeAction={(e) => this.setState({ email: e })} />
                  </NanoFlex>
                  <NanoFlex className="formWrapper">
                    <Input title={this.translation.contact.subject} placeholder={this.translation.contact.subject} onChangeAction={(e) => this.setState({ subject: e })} />
                  </NanoFlex>
                  <NanoFlex className="formWrapper">
                    <Textarea title={this.translation.contact.message} placeholder={this.translation.contact.writeMessage} onChangeAction={(e) => this.setState({ message: e })} />
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="bottomWrapper">
                  <MainButton label={this.translation.global.send} type="submit" clickAction={this.onContact} />
                </NanoFlex>
              </form>
            </NanoFlex>
          </NanoFlex>
        )}
      </StyledContactModal>
    );
  }
}
