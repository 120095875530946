import moment from "moment";

export const elementMatches = (el, selector) => {
  return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
};

export const elementMatchesAny = (el, selectorArray) => {
  for (let idx = 0; idx < selectorArray.length; idx++) {
    let v = selectorArray[idx];
    if (elementMatches(el, v) || el.parentElement.closest(v)) {
      return true;
    }
  }
  return false;
};

export const validateEmailFormat = function (val) {
  var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(val.toLowerCase());
};

export const getByPath = function (path, val) {
  return path.split(".").reduce((p, c) => p?.[c] || null, val);
};

export const getDateTime = function (date) {
  return moment(date).format("YYYY-MM-DDTHH:mm:ss") + "Z";
};

export const downloadBlob = function (blob, filename) {
  var url = window.URL.createObjectURL(blob);
  var a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const flattenByKey = function (arr, key) {
  return arr ? arr.reduce((r, i) => [...r, i, ...flattenByKey(i[key], key)], []) : [];
};

export const convertTreeToElements = function (tree) {
  return tree.map((t, i) => {
    return {
      code: t.code,
      name: t.name,
      vartype: t.type,
      type: "group",
      sortPosition: t.sortPosition,
      sortOrientation: t.sortOrientation,
      elements: t.children.length > 0 ? convertTreeToElements(t.children) : [],
    };
  });
};

export const buildPivotColumnsLines = function (columns, lines, type) {
  return {
    categories: [
      {
        //Columns
        category: 1,
        relation: null,
        elements: convertTreeToElements(columns),
      },
      {
        //Lines
        category: 0,
        relation: null,
        elements: convertTreeToElements(lines),
      },
      {
        //Metrics
        category: 2,
        relation: 0,
        elements: [],
      },
    ],
    type: type,
  };
};

export const buildPivotColumns = function (columns) {
  return {
    //Columns
    category: "columns",
    relation: null,
    elements: convertTreeToElements(columns),
  };
};

export const buildPivotLines = function (lines) {
  return {
    //Lines
    category: "lines",
    relation: null,
    elements: convertTreeToElements(lines),
  };
};

export const convertElementsToTree = function (elements) {
  return elements.map((t, i) => {
    return {
      title: t.name,
      name: t.name,
      code: t.code,
      vartype: t.type,
      sortPosition: t.sortPosition,
      sortOrientation: t.sortOrientation,
      children: t.elements.length > 0 ? convertElementsToTree(t.elements) : [],
    };
  });
};

export const buildColumnsLinesPivot = function (settings) {
  let pivotColumns = settings?.filter((s) => s.category === 1)[0]?.elements ?? [];
  let pivotLines = settings?.filter((s) => s.category === 0)[0]?.elements ?? [];
  return {
    columns: convertElementsToTree(pivotColumns),
    lines: convertElementsToTree(pivotLines),
  };
};

export const flattenElements = function (data) {
  return data.reduce((r, { children, ...rest }) => {
    r.push(rest);
    if (children) r.push(...flattenElements(children));
    return r;
  }, []);
};

export const findRecursive = function (array, predicate) {
  var result;
  array.some((o) => (predicate(o) && (result = o)) || (result = findRecursive(o.children ?? [], predicate)));
  return result;
};

export const formatTime = function (time) {
  if (time.toString().length <= 3) return "0" + time.toString().slice(0, 1) + ":" + (time.toString().length === 1 ? "00" : time.toString().slice(1, 4));
  else return time.toString().slice(0, 2) + ":" + time.toString().slice(2, 4);
};
