import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

//IMGS
import { ReactComponent as InForce } from "../../icons/inForceIcon.svg";
import { ReactComponent as Historic } from "../../icons/historicIcon.svg";

const StyledInForceOrHistoricSelect = styled(NanoFlex)`
  /* width: auto; */
  height: auto;
  margin-bottom: 8px;
  .inForceOrHistoricSelectWrapper {
    /* width: auto; */
    .selectBtnWrapper {
      /* width: 136px; */
      background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
      cursor: pointer;
      padding: 8px;
      border-radius: 60px;
      margin-right: 8px;
      user-select: none;
      .titleSelect {
        width: auto;
        p {
          color: ${(props) => props.theme.color.colorsPalette.grey};
        }
      }
      .iconWrapper {
        width: auto;
        svg {
          width: auto;
          height: 14px;
          path {
            fill: ${(props) => props.theme.color.colorsPalette.grey};
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
      &:hover {
        opacity: 0.7;
      }
    }

    .inForceSelect {
      background-color: ${(props) => (props.inForce ? props.theme.color.colorsPalette.inForce : props.theme.color.colorsPalette.lighterGrey)};
      .titleSelect {
        p {
          color: ${(props) => (props.inForce ? props.theme.color.main.white : props.theme.color.colorsPalette.grey)};
        }
      }
      .iconWrapper {
        svg {
          path {
            fill: ${(props) => (props.inForce ? props.theme.color.main.white : props.theme.color.colorsPalette.grey)};
          }
        }
      }
    }

    .inHistoricSelect {
      background-color: ${(props) => (props.inHistoric ? props.theme.color.colorsPalette.historic : props.theme.color.colorsPalette.lighterGrey)};
      .titleSelect {
        p {
          color: ${(props) => (props.inHistoric ? props.theme.color.main.white : props.theme.color.colorsPalette.grey)};
        }
      }
      .iconWrapper {
        svg {
          path {
            fill: ${(props) => (props.inHistoric ? props.theme.color.main.white : props.theme.color.colorsPalette.grey)};
          }
        }
      }
    }
  }
`;

export default class InForceOrHistoricSelect extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledInForceOrHistoricSelect justifyContent="flex-start" inForce={this.props.selected === 1} inHistoric={this.props.selected === 2}>
        <NanoFlex className="inForceOrHistoricSelectWrapper" justifyContent="flex-start" alignItems="flex-start">
          <NanoFlex className="selectBtnWrapper inForceSelect" justifyContent="space-between" onClick={() => this.props.onSelecteStateChange(1)}>
            <NanoFlex className="titleSelect">
              <Type.p>{this.translation.filters.inForce}</Type.p>
            </NanoFlex>
            <NanoFlex className="iconWrapper">
              <InForce />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="selectBtnWrapper inHistoricSelect" justifyContent="space-between" onClick={() => this.props.onSelecteStateChange(2)}>
            <NanoFlex className="titleSelect">
              <Type.p>{this.translation.filters.inHistoric}</Type.p>
            </NanoFlex>
            <NanoFlex className="iconWrapper">
              <Historic />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledInForceOrHistoricSelect>
    );
  }
}
