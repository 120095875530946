/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

//IMGS
import { ReactComponent as Close } from "../../icons/close.svg";

//COMPONENTS
import Input from "../Forms/Input.js";
import Textarea from "../Forms/Textarea.js";
import Evaluation from "../Forms/Evaluation.js";
import MainButton from "../Buttons/MainButton.js";
import Loading from "../../../Global/Elements/Loading/Loading.js";

//Services
import userInteractionsService from "./../../../../services/UserInteractionsService";

const StyledFeedbackModal = styled(NanoFlex)`
  width: 434px;
  background-color: ${(props) => props.theme.color.main.white};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden;
  .feedbackModalWrapper {
    .headerModal {
      position: relative;
      height: auto;
      padding: 16px;
      background-color: ${(props) => props.theme.color.main.yellow};
      h3 {
        text-transform: uppercase;
      }
      .closeBtn {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 6px;
        right: 6px;
        border-radius: 60px;
        cursor: pointer;
        svg {
          width: 11px;
          height: auto;
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
        &:hover {
          background-color: #ffffff8f;
        }
      }
    }
    .bodyModal {
      padding: 16px 32px 32px 32px;

      form {
        width: 100%;
      }
      .formContainer {
        height: auto;
        margin-top: 16px;
        .formWrapper {
          width: 100%;
          margin-bottom: 8px;
          .titleForm {
            margin-bottom: 4px;
            text-transform: uppercase;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .spaceForm {
          margin-top: 8px;
        }
      }
      .bottomWrapper {
        margin-top: 16px;
        height: auto;
      }
    }
  }
`;

export class FeedbackModal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      navigationIndex: 3,
      informationIndex: 3,
      portalIndex: 3,
      suggestions: "",
      loading: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onCancel = () => {
    this.setState(() => {
      if (this.props.cancelAction) this.props.cancelAction();
    });
  };

  onFeedback = () => {
    this.setState({ loading: true }, () => {
      userInteractionsService
        .submitFeedback({
          name: this.state.name,
          email: this.state.email,
          navigationIndex: this.state.navigationIndex,
          informationIndex: this.state.informationIndex,
          portalIndex: this.state.portalIndex,
          suggestions: this.state.suggestions,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
          }
        })
        .then((data) => {
          if (data) {
            this.setState({ loading: false });
            this.props.cancelAction();
          }
        });
    });
  };

  render() {
    let loading = this.state.loading;

    return (
      <StyledFeedbackModal justifyContent="flex-start">
        {loading && (
          <NanoFlex className="loadingWrapper">
            <Loading />
          </NanoFlex>
        )}
        {!loading && (
          <NanoFlex className="feedbackModalWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="headerModal">
              <Type.h3>
                <b>{this.translation.global.giveUsFeedback}</b>
              </Type.h3>
              <NanoFlex className="closeBtn" onClick={this.onCancel}>
                <Close />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="bodyModal" flexDirection="column" justifyContent="flex-start">
              <form>
                <NanoFlex className="formContainer" flexDirection="column">
                  <NanoFlex className="formWrapper">
                    <Input title={this.translation.contact.name} placeholder={this.translation.contact.nameSurname} onChangeAction={(e) => this.setState({ name: e })} />
                  </NanoFlex>
                  <NanoFlex className="formWrapper">
                    <Input title={this.translation.contact.email} placeholder={this.translation.contact.email} onChangeAction={(e) => this.setState({ email: e })} />
                  </NanoFlex>
                  <NanoFlex className="formWrapper spaceForm" justifyContent="flex-start">
                    <Type.p>Responda às perguntas, classificando de 1 (mínimo) a 5 (máximo)</Type.p>
                  </NanoFlex>
                  <NanoFlex className="formWrapper spaceForm">
                    <Evaluation title="Foi fácil navegar nesta página e encontrar a informação?" selected={this.state.navigationIndex} onChangeAction={(e) => this.setState({ navigationIndex: e })} />
                  </NanoFlex>
                  <NanoFlex className="formWrapper spaceForm">
                    <Evaluation title="A informação que obteve é útil?" selected={this.state.informationIndex} onChangeAction={(e) => this.setState({ informationIndex: e })} />
                  </NanoFlex>
                  <NanoFlex className="formWrapper spaceForm">
                    <Evaluation title="Considera que este Portal tem uma imagem agradável?" selected={this.state.portalIndex} onChangeAction={(e) => this.setState({ portalIndex: e })} />
                  </NanoFlex>
                  <NanoFlex className="formWrapper">
                    <Textarea title={this.translation.feedback.suggestions} placeholder={this.translation.feedback.writeSuggestions} onChangeAction={(e) => this.setState({ suggestions: e })} />
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="bottomWrapper">
                  <MainButton label={this.translation.global.send} type="submit" clickAction={this.onFeedback} />
                </NanoFlex>
              </form>
            </NanoFlex>
          </NanoFlex>
        )}
      </StyledFeedbackModal>
    );
  }
}
