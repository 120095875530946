import React from "react";
import styled from "styled-components";
import BaseComponent from "../../../BaseComponent.js";
import NanoFlex from "../../../Global/NanoFlex.js";
import ImageGallery from "react-image-gallery";

// scss
import "react-image-gallery/styles/scss/image-gallery.scss";

//Images
import ArrowRight from "../../../Global/icons/arrowRight-round.svg";
import ArrowLeft from "../../../Global/icons/arrowLeft-round.svg";

const StyledImagesViewer = styled(NanoFlex)`
  .image-gallery {
    display: flex;
    width: 100%;
    height: 100%;
    .image-gallery-content {
      display: flex;
      width: 100%;
      height: 100%;
      .image-gallery-slide-wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 16px;
        background-color: ${(props) => props.theme.color.colorsPalette.opacityGrey};
        .image-gallery-swipe {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          align-content: center;
          .image-gallery-slides {
            width: 100%;
            height: 100%;
            display: flex;
            .image-gallery-slide {
              padding: 0 40px;
              .image-gallery-image {
                max-height: 100%;
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
      .image-gallery-thumbnails-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        align-content: flex-start;
        height: 100%;
        width: 200px;
        margin: 0 0 0 16px;

        .image-gallery-thumbnails {
          display: flex;
          align-items: flex-start;
          min-height: 100%;
          /* overflow: auto; 
          padding-right: 2px;*/
          .image-gallery-thumbnails-container {
            .image-gallery-thumbnail {
              cursor: pointer;
              width: 100%;
              height: 170px;
              padding: 8px;
              background-color: ${(props) => props.theme.color.colorsPalette.opacityGrey};
              margin-bottom: 8px;
              transition: ${(props) => props.theme.transition};
              outline: none;
              border: 0;
              .image-gallery-thumbnail-inner {
                width: 100%;
                height: 100%;
                display: flex;
                overflow: hidden;
                transition: ${(props) => props.theme.transition};
                img {
                  transition: ${(props) => props.theme.transition};
                  height: 100%;
                  object-fit: cover;
                }
              }
              &:hover {
                border: 0;
                background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
                transition: ${(props) => props.theme.transition};
                img {
                  transform: scale(1.2);
                  transition: ${(props) => props.theme.transition};
                }
              }
              &:focus {
                border: 0;
                background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
                transition: ${(props) => props.theme.transition};
              }
              &:last-child {
                margin-bottom: 0;
              }
            }

            .active {
              background-color: ${(props) => props.theme.color.colorsPalette.lightBlue};
              border: 0;
              pointer-events: none;
              transition: ${(props) => props.theme.transition};
            }
          }
        }
      }
    }
    .fullscreen {
      padding: 32px;
    }
  }
  /* GALERRY BUTTONS */

  .image-gallery-left-nav {
    height: 100%;
    background: ${(props) => props.theme.color.main.white};
    width: 48px;
    display: flex;
    opacity: 0.7;
    justify-content: center;
    align-items: center;
    align-content: center;
    filter: unset;
    background-image: url(${ArrowLeft});
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 24px;
    padding: 12px;
    svg {
      display: none;
    }
  }
  .image-gallery-right-nav {
    height: 100%;
    background: ${(props) => props.theme.color.main.white};
    width: 48px;
    display: flex;
    opacity: 0.5;
    justify-content: center;
    align-items: center;
    align-content: center;
    filter: unset;
    background-image: url(${ArrowRight});
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 24px;
    padding: 12px;
    svg {
      display: none;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .image-gallery-fullscreen-button {
    filter: unset;
    background: ${(props) => props.theme.color.colorsPalette.lightGrey};
    border-radius: 60px;
    padding: 12px;
    display: flex;
    right: 12px;
    bottom: 12px;
    .image-gallery-svg {
      height: 18px;
      width: auto;
    }
    &:hover {
      background: ${(props) => props.theme.color.colorsPalette.lightBlue};
      color: ${(props) => props.theme.color.main.white};
    }
  }
  /* END GALERRY BUTTONS */
`;

export default class ImagesViewer extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  imageClick(el) {
    window.open(el.currentTarget.children[0].attributes.src.nodeValue, "_blank");
  }

  render() {
    return (
      <StyledImagesViewer>
        <ImageGallery items={this.props.images} onClick={(e) => this.imageClick(e)} onScreenChange={true} thumbnailPosition="right" showPlayButton={false} disableThumbnailScroll={false} />
      </StyledImagesViewer>
    );
  }
}
