import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

const StyledEvaluation = styled(NanoFlex)`
  height: 100%;
  .evaluationWrapper {
    .evaluationBlock {
      margin-top: 8px;
      position: relative;
      .dotsContainer {
        z-index: 1;
        .dotWrapper {
          .dot {
            width: 12px;
            height: 12px;
            background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
            cursor: pointer;
            border-radius: 16px;
            &:hover {
              opacity: 0.7;
            }
          }
          .dotLabel {
            margin-top: 4px;
          }
        }
        .selectDot {
          pointer-events: none;
          .dot {
            background-color: ${(props) => props.theme.color.main.yellow};
          }
          .dotLabel {
            p {
              font-weight: bold;
            }
          }
        }
      }
      .lineContainer {
        position: absolute;
        top: 5px;
        height: 0.5px;
        background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
      }
    }
  }
`;

export default class Evaluation extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledEvaluation justifyContent="flex-start">
        <NanoFlex className="evaluationWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <NanoFlex className="evaluationTitle" justifyContent="flex-start">
            <Type.p>
              <b>{this.props.title}</b>
            </Type.p>
          </NanoFlex>
          <NanoFlex className="evaluationBlock">
            <NanoFlex className="dotsContainer">
              <NanoFlex
                className={`dotWrapper ${this.props.selected === 1 ? "selectDot" : ""}`}
                flexDirection="column"
                onClick={(e) => {
                  if (this.props.onChangeAction) {
                    this.props.onChangeAction(1);
                  }
                }}>
                <NanoFlex className="dot"></NanoFlex>
                <NanoFlex className="dotLabel">
                  <Type.p>1</Type.p>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex
                className={`dotWrapper ${this.props.selected === 2 ? "selectDot" : ""}`}
                flexDirection="column"
                onClick={(e) => {
                  if (this.props.onChangeAction) {
                    this.props.onChangeAction(2);
                  }
                }}>
                <NanoFlex className="dot"></NanoFlex>
                <NanoFlex className="dotLabel">
                  <Type.p>2</Type.p>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex
                className={`dotWrapper ${this.props.selected === 3 ? "selectDot" : ""}`}
                flexDirection="column"
                onClick={(e) => {
                  if (this.props.onChangeAction) {
                    this.props.onChangeAction(3);
                  }
                }}>
                <NanoFlex className="dot"></NanoFlex>
                <NanoFlex className="dotLabel">
                  <Type.p>3</Type.p>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex
                className={`dotWrapper ${this.props.selected === 4 ? "selectDot" : ""}`}
                flexDirection="column"
                onClick={(e) => {
                  if (this.props.onChangeAction) {
                    this.props.onChangeAction(4);
                  }
                }}>
                <NanoFlex className="dot"></NanoFlex>
                <NanoFlex className="dotLabel">
                  <Type.p>4</Type.p>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex
                className={`dotWrapper ${this.props.selected === 5 ? "selectDot" : ""}`}
                flexDirection="column"
                onClick={(e) => {
                  if (this.props.onChangeAction) {
                    this.props.onChangeAction(5);
                  }
                }}>
                <NanoFlex className="dot"></NanoFlex>
                <NanoFlex className="dotLabel">
                  <Type.p>5</Type.p>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="lineContainer"></NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledEvaluation>
    );
  }
}
