import React from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";

// IMAGES
import { ReactComponent as PrintIcon } from "../../icons/printIcon.svg";


const StyledPrintButton = styled(NanoFlex)`
  height: auto;
  width: auto;
  margin-right: 8px;
  user-select: none;
  .buttonWithIconWrapper {
    width: 34px;
    height: 34px;
    cursor: pointer;
    pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
    padding: 8px;
    user-select: none;
    cursor: pointer;
    border-radius: 60px;
    .iconBtn {
      svg {
        height: 18px;
        width: auto;
        path {
          fill: ${(props) => (props.igtColor ? props.theme.color.main.igt : props.renColor ? props.theme.color.main.ren : props.planColor ? props.theme.color.colorsPalette.darkBlue : props.disabled ? props.theme.color.colorsPalette.lightGrey : props.theme.color.main.font)};
        }
      }
    }
    &:hover {
      background-color: ${(props) => props.theme.color.colorsPalette.opacityGrey};
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;

const PrintButton = (props) => {
  return (
    <StyledPrintButton className="buttonWithIcon"  disabled={props.disabled} igtColor={props.igtColor} renColor={props.renColor} planColor={props.planColor}>
      <NanoFlex className="buttonWithIconWrapper" justifyContent="flex-end" onClick={() => props.clickAction(props.id)}>
        <NanoFlex className="iconBtn">
          <PrintIcon />
        </NanoFlex>
      </NanoFlex>
    </StyledPrintButton>
  );
};

// Set default props
PrintButton.defaultProps = {
  id: 0,
  type: "submit",
  src: "",
  clickAction: () => null,
};

export default PrintButton;
