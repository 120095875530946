import React from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";

const StyledLoading = styled(NanoFlex)`
  .lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(0.6);
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${(props) => props.theme.color.main.yellow};
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
 /*ANIMATIONS*/
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
    transform: scale(0.6) rotate(180);
    background: ${(props) => props.theme.color.colorsPalette.grey};
  }
  50% {
    opacity: 0.5;
    transform: scale(1) rotate(-180);
    background: ${(props) => props.theme.color.colorsPalette.lightGrey};
  }
}


`;

const Loading = (props) => {
  return (
    <StyledLoading>
      <NanoFlex>
        <div className="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </NanoFlex>
    </StyledLoading>
  );
};

export default Loading;

// --- // Documentation // --- //
/*

import Loading from './Loading.js';

<Loading/>
*/
