import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//IMGS
import { ReactComponent as Collapse } from "../../Global/icons/collapseIcon.svg";
import { ReactComponent as MapIcon } from "../../Global/icons/mapIcon.svg";
import { ReactComponent as HistoricIcon } from "../../Global/icons/historicIcon.svg";
import { ReactComponent as InForceIcon } from "../../Global/icons/inForceIcon.svg";

//COMPONENTS

const StyledListPlans = styled(NanoFlex)`
  width: ${(props) => (props.isCollapsed ? "48px" : "344px")};
  transition: ${(props) => props.theme.transition};

  .listPlansWrapper {
    border-radius: 20px 0 0 20px;
    background-color: ${(props) => props.theme.color.main.white};
    overflow: hidden;

    .headerFilters {
      position: relative;
      height: ${(props) => (props.isCollapsed ? "100%" : "auto")};
      padding: 8px;
      background-color: ${(props) => props.theme.color.main.yellow};
      h5 {
        margin-top: 3px;
        display: ${(props) => (props.isCollapsed ? "none" : "flex")};
        text-transform: uppercase;
      }
      .mapIcon {
        display: ${(props) => (props.isCollapsed ? "flex" : "none")};
        svg {
          width: 22px;
          height: auto;
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
      }
      .collapseBtn {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 6px;
        right: ${(props) => (props.isCollapsed ? "auto" : "6px")};
        border-radius: 60px;
        cursor: pointer;
        svg {
          width: 11px;
          height: auto;
          transform: rotate(${(props) => (props.isCollapsed ? "180deg" : "0")});
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
        &:hover {
          background-color: #ffffff8f;
        }
      }
    }
    .listContainer {
      padding: 0 16px;
      margin: 16px 0;
      display: ${(props) => (props.isCollapsed ? "none" : "flex")};
      overflow: auto;
      .listWrapper {
        .blockList {
          margin-bottom: 16px;
          .titleBlockList {
            height: auto;
            margin-bottom: 4px;
            p {
              text-transform: uppercase;
            }
          }
          .listItems {
            height: auto;
            .itemWrapper {
              cursor: pointer;
              margin-bottom: 8px;
              background-color: ${(props) => props.theme.color.colorsPalette.opacityGrey};
              height: auto;
              min-height: 56px;
              .iconTypeWrapper {
                width: auto;
                padding: 8px;
                svg {
                  height: 14px;
                  width: auto;
                  path {
                    fill: ${(props) => props.theme.color.main.white};
                  }
                }
              }
              .infoWrapper {
                padding: 8px;
              }
              &:last-child {
                margin-bottom: 0;
              }
              &:hover {
                background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
              }
            }
            .historicItem {
              .iconTypeWrapper {
                background-color: ${(props) => props.theme.color.colorsPalette.historic};
                .historicIcon {
                  display: flex;
                }
                .inForceIcon {
                  display: none;
                }
              }
            }
            .inForceItem {
              .iconTypeWrapper {
                background-color: ${(props) => props.theme.color.colorsPalette.inForce};
                .historicIcon {
                  display: none;
                }
                .inForceIcon {
                  display: flex;
                }
              }
            }
            .selectedItem {
              pointer-events: none;
              background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
              .infoWrapper {
                p {
                  font-weight: bold;
                }
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export default class ListPlans extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      isCollapsed: JSON.parse(localStorage.getItem("isFiltersCollapsed")) ?? false,
    };
  };

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isCollapsed !== prevState.isCollapsed) {
      localStorage.setItem("isFiltersCollapsed", this.state.isCollapsed);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  toggleCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  render() {
    let results = this.props.plans;
    return (
      <StyledListPlans isCollapsed={this.state.isCollapsed}>
        <NanoFlex className="listPlansWrapper" flexDirection="column" justifyContent="space-between">
          <NanoFlex className="headerFilters">
            <Type.h5>
              <b>{this.translation.global.plansList}</b>
            </Type.h5>
            <NanoFlex className="mapIcon">
              <MapIcon />
            </NanoFlex>
            <NanoFlex className="collapseBtn" onClick={this.toggleCollapse}>
              <Collapse />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="listContainer" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="listWrapper" flexDirection="column" justifyContent="flex-start">
              {/* BLOCK LIST */}
              <NanoFlex className="blockList" flexDirection="column" justifyContent="flex-start">
                {/* LIST LOCATION */}
                <NanoFlex className="titleBlockList" justifyContent="flex-start">
                  <Type.p>
                    <b>{this.props.designation}</b>
                  </Type.p>
                </NanoFlex>
                {/* LIST */}
                <NanoFlex className="listItems" flexDirection="column" justifyContent="flex-start">
                  {results &&
                    results.map((r) => {
                      return (
                        <NanoFlex
                          key={`listItemsPlans_${r.idRaster}}`}
                          className={`itemWrapper planTypeItem ${r.status === 1 ? "inForceItem" : "historicItem"} ${(this.props.selectedRaster ?? results[0].idRaster) === r.idRaster ? "selectedItem" : ""}`}
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          onClick={() => this.props.onChangePlan(r.idRaster)}>
                          <NanoFlex className="iconTypeWrapper">
                            <HistoricIcon className="historicIcon" />
                            <InForceIcon className="inForceIcon" />
                          </NanoFlex>
                          <NanoFlex className="infoWrapper" justifyContent="flex-start">
                            <Type.p>{r.name}</Type.p>
                          </NanoFlex>
                        </NanoFlex>
                      );
                    })}
                </NanoFlex>
                {/* END LIST */}
                {/* LIST LOCATION */}
              </NanoFlex>
              {/* END BLOCK LIST */}
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledListPlans>
    );
  }
}
