import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import Input from "../../Global/Elements/Forms/Input.js";
import SingleSelectionDropdown from "../../Global/Elements/Forms/SingleSelectionDropdown.js";
import MultipleSelectionDropdown from "../../Global/Elements/Forms/MultipleSelectionDropdown.js";
import InForceOrHistoricSelect from "../../Global/Elements/Forms/InForceOrHistoricSelect.js";
import Textarea from "../../Global/Elements/Forms/Textarea.js";
import Evaluation from "../../Global/Elements/Forms/Evaluation.js";
import MapSelect from "../../Global/Elements/Forms/MapSelect.js";
import MainButton from "../../Global/Elements/Buttons/MainButton.js";
import SimpleButton from "../../Global/Elements/Buttons/SimpleButton.js";
import PrintButton from "../../Global/Elements/Buttons/PrintButton.js";
import PDFButton from "../../Global/Elements/Buttons/PDFButton.js";
import FeedbackButton from "../../Global/Elements/Buttons/FeedbackButton.js";
import Modal from "../../Global/Elements/Modals/Modal.js";
import { ContactModal } from "../../Global/Elements/Modals/ContactModal.js";
import { FeedbackModal } from "../../Global/Elements/Modals/FeedbackModal.js";
import  Loading  from "../../Global/Elements/Loading/Loading.js";

//IMAGES
import SNITLogo from "../../Global/images/snitLogo.png";

const StyledGuidelines = styled(NanoFlex)`
  padding: 30px;
  height: auto;
  background-color: ${(props) => props.theme.color.main.white};
  .guidelinesWrapper {
    padding: 30px;
    background-color: ${(props) => props.theme.color.main.font};
    .headerGuidelines {
      .logoWrapper {
        height: 80px;
        width: auto;
        margin-bottom: 16px;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
      h1 {
        color: ${(props) => props.theme.color.main.white};
      }
    }

    .guidelineBlock {
      margin-top: 16px;
      padding: 8px;
      border-top: 1px solid ${(props) => props.theme.color.main.white};

      .titleBlock {
        margin: 8px 0;
        h4 {
          color: ${(props) => props.theme.color.main.white};
        }
      }
      .blockWrapper {
        padding: 16px;
        background-color: ${(props) => props.theme.color.main.white};
        .colorBlock {
          margin-right: 8px;
          margin-bottom: 8px;
          width: auto;
          height: auto;
          .colorTitle {
            margin-bottom: 4px;
            h5 {
              color: ${(props) => props.theme.color.main.font};
            }
          }
          .color {
            background-color: ${(props) => props.theme.color.main.font};
            width: 184px;
            height: 60px;
            position: relative;
            .hexCode {
              position: absolute;
              right: 8px;
              bottom: 8px;
              height: auto;
              width: auto;
              h6 {
                color: ${(props) => props.theme.color.main.font};
              }
            }
          }
          /* MAIN COLORS */
          .font {
            background-color: ${(props) => props.theme.color.main.font};
            border: ${(props) => props.theme.color.main.font};
            .hexCode {
              h6 {
                color: ${(props) => props.theme.color.main.white};
              }
            }
          }
          .yellow {
            background-color: ${(props) => props.theme.color.main.yellow};
            border: ${(props) => props.theme.color.main.yellow};
          }
          .igt {
            background-color: ${(props) => props.theme.color.main.igt};
            border: ${(props) => props.theme.color.main.igt};
          }
          .ren {
            background-color: ${(props) => props.theme.color.main.ren};
            border: ${(props) => props.theme.color.main.ren};
          }
          .white {
            background-color: ${(props) => props.theme.color.main.white};
            border: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
          }
          .error {
            background-color: ${(props) => props.theme.color.main.error};
            border: ${(props) => props.theme.color.main.error};
            .hexCode {
              h6 {
                color: ${(props) => props.theme.color.main.white};
              }
            }
          }
          /* COLOR PALETTE */
          .grey {
            background-color: ${(props) => props.theme.color.colorsPalette.grey};
            border: ${(props) => props.theme.color.colorsPalette.grey};
            .hexCode {
              h6 {
                color: ${(props) => props.theme.color.main.white};
              }
            }
          }
          .lightGrey {
            background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
            border: ${(props) => props.theme.color.colorsPalette.lightGrey};
          }
          .lighterGrey {
            background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
            border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
          }
          .opacityGrey {
            background-color: ${(props) => props.theme.color.colorsPalette.opacityGrey};
            border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
          }
          .darkBlue {
            background-color: ${(props) => props.theme.color.colorsPalette.darkBlue};
            border: ${(props) => props.theme.color.colorsPalette.darkBlue};
            .hexCode {
              h6 {
                color: ${(props) => props.theme.color.main.white};
              }
            }
          }
          .lightBlue {
            background-color: ${(props) => props.theme.color.colorsPalette.lightBlue};
            border: ${(props) => props.theme.color.colorsPalette.lightBlue};
          }
          .historic {
            background-color: ${(props) => props.theme.color.colorsPalette.historic};
            border: ${(props) => props.theme.color.colorsPalette.historic};
          }
          .inForce {
            background-color: ${(props) => props.theme.color.colorsPalette.inForce};
            border: ${(props) => props.theme.color.colorsPalette.inForce};
          }
        }
        .typeBlock {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .formWrapper {
          margin-bottom: 8px;
          .titleForm {
            margin-bottom: 4px;
            text-transform: uppercase;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export class Guidelines extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      openContactModal: false,
      openFeedbackModal: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledGuidelines flexDirection="column" justifyContent="flex-start">
        <NanoFlex className="guidelinesWrapper" flexDirection="column">
          {/* HEADER GUIDLINES */}
          <NanoFlex className="headerGuidelines" flexDirection="column">
            <NanoFlex className="logoWrapper">
              <img src={SNITLogo} alt="SNIT Logo" />
            </NanoFlex>
            <Type.h1>
              <b>GUIDELINES</b>
            </Type.h1>
          </NanoFlex>

          {/* COLOR PALETTE */}
          <NanoFlex className="guidelineBlock" flexDirection="column" alignItems="flex-start">
            <NanoFlex className="titleBlock" justifyContent="flex-start">
              <Type.h4>
                <b>COLOR PALETTE</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="blockWrapper" flexWrap="wrap" justifyContent="flex-start">
              {/* COLOR BLOCK - font */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>main.font</Type.h5>
                </NanoFlex>
                <NanoFlex className="color font">
                  <NanoFlex className="hexCode">
                    <Type.h6>#2C2C2A</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* COLOR BLOCK - yellow */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>main.yellow</Type.h5>
                </NanoFlex>
                <NanoFlex className="color yellow">
                  <NanoFlex className="hexCode">
                    <Type.h6>#FFD900</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* COLOR BLOCK - igt */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>main.igt</Type.h5>
                </NanoFlex>
                <NanoFlex className="color igt">
                  <NanoFlex className="hexCode">
                    <Type.h6>#5CBAA7</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* COLOR BLOCK - ren */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>main.ren</Type.h5>
                </NanoFlex>
                <NanoFlex className="color ren">
                  <NanoFlex className="hexCode">
                    <Type.h6>#7FC689</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* COLOR BLOCK - white */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>main.white</Type.h5>
                </NanoFlex>
                <NanoFlex className="color white">
                  <NanoFlex className="hexCode">
                    <Type.h6>#FFFFFF</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* COLOR BLOCK - error */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>main.error</Type.h5>
                </NanoFlex>
                <NanoFlex className="color error">
                  <NanoFlex className="hexCode">
                    <Type.h6>#E30000</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="blockWrapper" flexWrap="wrap" justifyContent="flex-start">
              {/* COLOR BLOCK - grey */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>colorsPalette.grey</Type.h5>
                </NanoFlex>
                <NanoFlex className="color grey">
                  <NanoFlex className="hexCode">
                    <Type.h6>#818181</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* COLOR BLOCK - lightGrey */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>colorsPalette.lightGrey</Type.h5>
                </NanoFlex>
                <NanoFlex className="color lightGrey">
                  <NanoFlex className="hexCode">
                    <Type.h6>#D3D3D3</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* COLOR BLOCK - lighterGrey */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>colorsPalette.lighterGrey</Type.h5>
                </NanoFlex>
                <NanoFlex className="color lighterGrey">
                  <NanoFlex className="hexCode">
                    <Type.h6>#E8E8E8</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* COLOR BLOCK - opacityGrey */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>colorsPalette.opacityGrey</Type.h5>
                </NanoFlex>
                <NanoFlex className="color opacityGrey">
                  <NanoFlex className="hexCode">
                    <Type.h6>#e8e8e86e</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* COLOR BLOCK - darkBlue */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>colorsPalette.darkBlue</Type.h5>
                </NanoFlex>
                <NanoFlex className="color darkBlue">
                  <NanoFlex className="hexCode">
                    <Type.h6>#105373</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* COLOR BLOCK - lightBlue*/}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>colorsPalette.lightBlue</Type.h5>
                </NanoFlex>
                <NanoFlex className="color lightBlue">
                  <NanoFlex className="hexCode">
                    <Type.h6>#249FDA</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* COLOR BLOCK - historic */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>colorsPalette.historic</Type.h5>
                </NanoFlex>
                <NanoFlex className="color historic">
                  <NanoFlex className="hexCode">
                    <Type.h6>#FF5924 </Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* COLOR BLOCK - inForce */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>colorsPalette.inForce</Type.h5>
                </NanoFlex>
                <NanoFlex className="color inForce">
                  <NanoFlex className="hexCode">
                    <Type.h6>#88AA00</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>

          {/* TYPOGRAPHY */}
          <NanoFlex className="guidelineBlock" flexDirection="column" alignItems="flex-start">
            <NanoFlex className="titleBlock" justifyContent="flex-start">
              <Type.h4>
                <b>TYPOGRAPHY</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="blockWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.h1>
                  <b>TYPE.H1</b> | 24px | Lorem ipsum dolor sit amet, <i>consectetur adipiscing elit</i>...
                </Type.h1>
              </NanoFlex>
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.h2>
                  <b>TYPE.H2</b> | 22px | Lorem ipsum dolor sit amet, <i>consectetur adipiscing elit</i>...
                </Type.h2>
              </NanoFlex>
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.h3>
                  <b>TYPE.H3</b> | 20px | Lorem ipsum dolor sit amet, <i>consectetur adipiscing elit</i>...
                </Type.h3>
              </NanoFlex>
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.h4>
                  <b>TYPE.H4</b> | 18px | Lorem ipsum dolor sit amet, <i>consectetur adipiscing elit</i>...
                </Type.h4>
              </NanoFlex>
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.h5>
                  <b>TYPE.H5</b> | 16px | Lorem ipsum dolor sit amet, <i>consectetur adipiscing elit</i>...
                </Type.h5>
              </NanoFlex>
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.h6>
                  <b>TYPE.H6</b> | 10px | Lorem ipsum dolor sit amet, <i>consectetur adipiscing elit</i>...
                </Type.h6>
              </NanoFlex>
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.p>
                  <b>TYPE.P</b> | 12px | Lorem ipsum <em>dolor sit amet</em>, <i>consectetur adipiscing elit</i>...
                </Type.p>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>

          {/* FORMS */}
          <NanoFlex className="guidelineBlock" flexDirection="column" alignItems="flex-start">
            <NanoFlex className="titleBlock" justifyContent="flex-start">
              <Type.h4>
                <b>FORMS</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="blockWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              {/* INPUT*/}
              <NanoFlex className="formWrapper">
                <Input title="Input" placeholder="Placeholder" />
              </NanoFlex>
              {/* SINGLE SELECTION DROPDOWN */}
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <Type.p className="titleForm">
                  <b>Single Select</b>
                </Type.p>
                <SingleSelectionDropdown
                  placeholder="Placeholder"
                  options={[
                    { id: 1, name: "teste" },
                    { id: 2, name: "teste 2" },
                    { id: 3, name: "teste 3" },
                    { id: 4, name: "teste 4" },
                    { id: 5, name: "teste 5" },
                    { id: 6, name: "teste 6" },
                    { id: 7, name: "teste maior texto" },
                    { id: 8, name: "teste super comprido que pode ocupar muito espaço" },
                    { id: 9, name: "teste 9" },
                  ]}
                />
              </NanoFlex>
              {/* MULTI SELECTION DROPDOWN */}
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <Type.p className="titleForm">
                  <b>Multi Select</b>
                </Type.p>
                <MultipleSelectionDropdown
                  placeholder="Placeholder"
                  options={[
                    { id: 1, name: "teste" },
                    { id: 2, name: "teste 2" },
                    { id: 3, name: "teste 3" },
                    { id: 4, name: "teste 4" },
                    { id: 5, name: "teste 5" },
                    { id: 6, name: "teste 6" },
                    { id: 7, name: "teste maior texto" },
                    { id: 8, name: "teste super comprido que pode ocupar muito espaço" },
                    { id: 9, name: "teste 9" },
                  ]}
                />
              </NanoFlex>

              {/* IN FORCE OR HISTORIC SELECT */}
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <InForceOrHistoricSelect inForce={false} inHistoric={true} />
              </NanoFlex>

              {/* MULTI SELECTION DROPDOWN with IN FORCE OR HISTORIC SELECT*/}
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <Type.p className="titleForm">
                  <b>Multi Select</b>
                </Type.p>
                <InForceOrHistoricSelect inForce={true} inHistoric={false} />
                <MultipleSelectionDropdown
                  placeholder="Placeholder"
                  options={[
                    { id: 1, name: "teste" },
                    { id: 2, name: "teste 2" },
                    { id: 3, name: "teste 3" },
                    { id: 4, name: "teste 4" },
                    { id: 5, name: "teste 5" },
                    { id: 6, name: "teste 6" },
                    { id: 7, name: "teste maior texto" },
                    { id: 8, name: "teste super comprido que pode ocupar muito espaço" },
                    { id: 9, name: "teste 9" },
                  ]}
                />
              </NanoFlex>

              {/* TEXTAREA */}
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <Textarea title="Textarea" placeholder="Placeholder" />
              </NanoFlex>
              {/* EVALUATION */}
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <Evaluation title="Evaluation Title" />
              </NanoFlex>

              {/* MAP SELECT */}
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                {/* <MapSelect title={this.translation.filters.region} acoresOcidental={false} acoresCentral={false} acoresOriental={true} madeira={false} norte={true} centro={true} estremadura={false} alentejo={true} algarve={false} /> */}
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>

          {/* BUTTONS */}
          <NanoFlex className="guidelineBlock" flexDirection="column" alignItems="flex-start">
            <NanoFlex className="titleBlock" justifyContent="flex-start">
              <Type.h4>
                <b>BUTTONS</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="blockWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <MainButton label="Main Button" />
              </NanoFlex>
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <SimpleButton label="Simple Button" />
              </NanoFlex>
              <NanoFlex className="formWrapper" alignItems="flex-start">
                <PrintButton disabled />
                <PrintButton igtColor />
                <PrintButton renColor />
                <PrintButton plantColor />
              </NanoFlex>
              <NanoFlex className="formWrapper" alignItems="flex-start">
                <PDFButton disabled />
                <PDFButton igtColor />
                <PDFButton renColor />
                <PDFButton plantColor />
              </NanoFlex>
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <FeedbackButton clickAction={() => this.setState({ openFeedbackModal: true })} />
                <Modal show={this.state.openFeedbackModal} content={<FeedbackModal cancelAction={() => this.setState({ openFeedbackModal: false })} nextAction={() => this.setState({ openFeedbackModal: false })} />} />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>

          {/* MODALS */}
          <NanoFlex className="guidelineBlock" flexDirection="column" alignItems="flex-start">
            <NanoFlex className="titleBlock" justifyContent="flex-start">
              <Type.h4>
                <b>MODAL</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="blockWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <SimpleButton label="Contact Modal" clickAction={() => this.setState({ openContactModal: true })} />
                <Modal show={this.state.openContactModal} content={<ContactModal cancelAction={() => this.setState({ openContactModal: false })} nextAction={() => this.setState({ openContactModal: false })} />} />
              </NanoFlex>
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <SimpleButton label="Feedback Modal" clickAction={() => this.setState({ openFeedbackModal: true })} />
                <Modal show={this.state.openFeedbackModal} content={<FeedbackModal cancelAction={() => this.setState({ openFeedbackModal: false })} nextAction={() => this.setState({ openFeedbackModal: false })} />} />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>

          {/* LOADING */}
          <NanoFlex className="guidelineBlock" flexDirection="column" alignItems="flex-start">
            <NanoFlex className="titleBlock" justifyContent="flex-start">
              <Type.h4>
                <b>LOADING</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="blockWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <Loading />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledGuidelines>
    );
  }
}
