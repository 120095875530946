import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import { NavLink } from "react-router-dom";
import Type from "../../Global/Typography.js";

//Services
import esasementService from "./../../../services/EasementService.js";

//Components
import Filters from "../Shared/Filters.js";
import Table from "./Table.js";
import Loading from "../../Global/Elements/Loading/Loading.js";

//Images
import SNITLogo from "../../Global/images/snitLogo.png";

const StyledREN = styled(NanoFlex)`
  .RENContainer {
    padding: 16px 32px;
    .logoContainer {
      height: 56px;
      cursor: pointer;
      img {
        width: auto;
        height: 100%;
        object-fit: contain;
      }
    }
    .mainContainer {
      margin-top: 16px;
      height: calc(100vh - 147px);
      .tableViewWrapper {
        transition: ${(props) => props.theme.transition};
        border-radius: 0 20px 20px 0;
        background-color: ${(props) => props.theme.color.main.white};
        overflow: hidden;
        border-left: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
        .titletableWrapper {
          height: auto;
          .titleView {
            padding: 8px;
            background-color: ${(props) => props.theme.color.main.ren};

            h5 {
              margin-top: 3px;
              color: ${(props) => props.theme.color.main.white};
            }
          }
        }
      }
    }
  }
`;

export class REN extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      filter: null,
      results: null,
      loading: false
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.filter) !== JSON.stringify(this.state.filter)) {
      if (this.state.filter) {
        this.getEasement();
      } else {
        this.setState({ results: null });
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getEasement = () => {
    this.setState({ loading: true }, () => {
      esasementService
        .getEasementsAsync(this.state.filter) //type, municipality, status
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
          }
        })
        .then((data) => {
          this.setState({ results: data, loading: false });
        });
    });
  };

  render() {
    let loading = this.state.loading;

    return (
      <StyledREN>
        <NanoFlex className="RENContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <NavLink to="/">
            <NanoFlex className="logoContainer" justifyContent="flex-start">
              <img src={SNITLogo} alt="SNIT Logo" />
            </NanoFlex>
          </NavLink>
          <NanoFlex className="mainContainer" justifyContent="flex-start">
            <Filters onFilterChange={(f) => this.setState({ filter: f })} plans={false} />
            <NanoFlex className="tableViewWrapper" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="titletableWrapper">
                <NanoFlex className="titleView">
                  <Type.h5>
                    <b>{this.translation.global.renTitle}</b>
                  </Type.h5>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="tableBlock">
                {loading && (
                  <NanoFlex className="loadingWrapper">
                    <Loading />
                  </NanoFlex>
                )}
                {!loading && <Table results={this.state.results} regionsMunicipalities={this.state.filter?.regionsMunicipalities} filterSelected={this.state.filter} />}
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledREN>
    );
  }
}
