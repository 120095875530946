import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//COMPONENTS
import PrintButton from "../../Global/Elements/Buttons/PrintButton.js";
import GoogleMapsButton from "../../Global/Elements/Buttons/GoogleMapsButton.js";
import ImagesViewer from "../../Global/Elements/Viewer/ImagesViewer.js";
import Modal from "../../Global/Elements/Modals/Modal.js";
import { PrintModal } from "../../Global/Elements/Modals/PrintModal.js";
import { MapsModal } from "../../Global/Elements/Modals/MapsModal.js";

const StyledPlansViewer = styled(NanoFlex)`
  padding: 16px 16px 32px 16px;
  position: relative;
  height: calc(100vh - 183px);
  .viewerWrapper {
    .topTableContainer {
      height: auto;
      margin-bottom: 8px;
      .asideInfo {
        height: auto;
      }
    }
    .viewerContainer {
      height: calc(100vh - 258px);
      min-height: calc(100vh - 258px);
      .mainViewer {
        padding: 16px;
        background-color: ${(props) => props.theme.color.colorsPalette.opacityGrey};
      }
      .viewerList {
        width: 200px;
        margin-left: 8px;
        background-color: red;
      }
    }
  }
`;

export default class PlansViewer extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      openPrintModal: false,
      openMapsModal: false,
    };
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    let result = this.props.plans.filter((f) => f.idRaster === (this.props.selectedRaster ? this.props.selectedRaster : this.props.plans[0].idRaster))[0];
    var images = [{ original: result?.finalImageName ?? result.imageName, thumbnail: result?.finalImageName ?? result.imageName }];
    var imagesToPrint = [{ original: result?.finalImageName ?? result.imageNamePrint, thumbnail: result?.finalImageName ?? result.imageNamePrint }];
    var legends = [];
    if (result?.listLegends && result?.listLegends.length > 0) {
      legends = result?.listLegends?.map((m) => {
        return { original: m.contentPath, thumbnail: m.contentPath };
      });
    }
    var sources = [];
    if (images?.length > 0 && legends.length > 0) sources = images.concat(legends);
    else sources = images;

    var sourcesToPrint = [];
    if (imagesToPrint?.length > 0 && legends.length > 0) sourcesToPrint = imagesToPrint.concat(legends);
    else sourcesToPrint = imagesToPrint;

    return (
      <StyledPlansViewer flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
        <NanoFlex className="viewerWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <NanoFlex className="topTableContainer">
            <NanoFlex className="asideInfo" justifyContent="flex-start">
              <Type.p>
                <b>{result?.name}</b>
              </Type.p>
            </NanoFlex>
            <NanoFlex className="asideInfo" justifyContent="flex-end">
              {JSON.stringify(this.props.location) !==
                JSON.stringify([
                  [0, 0],
                  [0, 0],
                ]) && <GoogleMapsButton clickAction={() => this.setState({ openMapsModal: true })} />}
              {JSON.stringify(this.props.location) !==
                JSON.stringify([
                  [0, 0],
                  [0, 0],
                ]) && <Modal show={this.state.openMapsModal} content={<MapsModal cancelAction={() => this.setState({ openMapsModal: false })} location={this.props.location} nextAction={() => this.setState({ openMapsModal: false })} />} />}
              <PrintButton planColor clickAction={() => this.setState({ openPrintModal: true })} />
              <Modal
                show={this.state.openPrintModal}
                content={<PrintModal data={sourcesToPrint} convert={this.props.convert} municipality={this.props.municipality} designation={this.props.designation} name={result?.name} cancelAction={() => this.setState({ openPrintModal: false })} nextAction={() => this.setState({ openPrintModal: false })} />}
              />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="viewerContainer">
            <ImagesViewer images={sources} />
            {/* <NanoFlex className="mainViewer"></NanoFlex>
            <NanoFlex className="viewerList"></NanoFlex> */}
          </NanoFlex>
        </NanoFlex>
      </StyledPlansViewer>
    );
  }
}
