import { BaseService } from "./BaseService";

const _apiUrl = "api/Easement/";

export class EasementService extends BaseService {
  //#region GET

  getEasementAsync(easementId) {
    return this.request(_apiUrl + "GetEasementAsync", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      body: easementId,
    });
  }

  getEasementDynamicAsync(easementDynamicId) {
    return this.request(_apiUrl + "GetEasementDynamicAsync", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      body: easementDynamicId,
    });
  }

  getEasementRastersAsync(easementRasterId) {
    return this.request(_apiUrl + "GetEasementRastersAsync", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      body: easementRasterId,
    });
  }

  getEasementsAsync(filter) {
    return this.request(_apiUrl + "GetEasementsAsync", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(filter),
    });
  }

  getRegionsAndMunicipalitiesAsync() {
    return this.request(_apiUrl + "GetRegionsAndMunicipalitiesAsync", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  //#endregion

  //#region EXPORT

  getEasementsExcel(filter) {
    return this.request(_apiUrl + "GetEasementsExcel", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(filter),
    });
  }
    
  //#endregion
}

const _easementService = new EasementService();

export default _easementService;
