import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import { NavLink } from "react-router-dom";
import Type from "../../Global/Typography.js";
import { useLocation } from "react-router-dom";

//Components
import ListPlans from "./ListPlans.js";
import PlansViewer from "./PlansViewer.js";

//Images
import SNITLogo from "../../Global/images/snitLogo.png";

const StyledPlans = styled(NanoFlex)`
  .IGTContainer {
    padding: 16px 32px;
    .logoContainer {
      height: 56px;
      cursor: pointer;
      img {
        width: auto;
        height: 100%;
        object-fit: contain;
      }
    }
    .mainContainer {
      margin-top: 16px;
      height: calc(100vh - 147px);
      .viewerPlansWrapper {
        transition: ${(props) => props.theme.transition};
        border-radius: 0 20px 20px 0;
        background-color: ${(props) => props.theme.color.main.white};
        overflow: hidden;
        border-left: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
        .titleWrapper {
          height: auto;
          .titleView {
            padding: 8px;
            background-color: ${(props) => props.theme.color.colorsPalette.lightBlue};
            h5 {
              margin-top: 3px;
              color: ${(props) => props.theme.color.main.white};
            }
          }
        }
      }
    }
  }
`;

export class Plans extends BaseComponent {
  constructor(props) {
    super(props);

    let prevRasters = JSON.parse(sessionStorage.getItem("rasters"));

    this.state = {
      rasters: prevRasters?.data ?? [],
      designation: prevRasters?.designation ?? "",
      municipality: prevRasters?.municipality ?? "",
      convert: prevRasters?.convert ?? "",
      location: prevRasters?.location ?? [],
      selectedRaster: null
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledPlans>
        <NanoFlex className="IGTContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <NavLink to="/">
            <NanoFlex className="logoContainer" justifyContent="flex-start">
              <img src={SNITLogo} alt="SNIT Logo" />
            </NanoFlex>
          </NavLink>
          <NanoFlex className="mainContainer" justifyContent="flex-start">
            <ListPlans designation={this.state.designation} plans={this.state.rasters} selectedRaster={this.state.selectedRaster} onChangePlan={(e) => this.setState({ selectedRaster: e })} />
            <NanoFlex className="viewerPlansWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="titleWrapper">
                <NanoFlex className="titleView">
                  <Type.h5>
                  <b>{this.translation.global.plans}</b>
                  </Type.h5>
                </NanoFlex>
              </NanoFlex>
              <PlansViewer plans={this.state.rasters} convert={this.state.convert} designation={this.state.designation} location={this.state.location} municipality={this.state.municipality} selectedRaster={this.state.selectedRaster} />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledPlans>
    );
  }
}
