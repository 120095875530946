import { BaseService } from "./BaseService";

const _apiUrl = "api/Instrument/";

export class InstrumentService extends BaseService {
  //#region GET

  getInstrumentAsync(instrumentId) {
    return this.request(_apiUrl + "GetInstrumentAsync", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      body: instrumentId,
    });
  }

  getInstrumentDynamicAsync(instrumentDynamicId) {
    return this.request(_apiUrl + "GetInstrumentDynamicAsync", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      body: instrumentDynamicId,
    });
  }

  getInstrumentRastersAsync(instrumentRasterId) {
    return this.request(_apiUrl + "GetInstrumentRastersAsync", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      body: instrumentRasterId,
    });
  }

  getInstrumentsAsync(filter) {
    return this.request(_apiUrl + "GetInstrumentsAsync", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(filter),
    });
  }

  getInstrumentTypesAsync() {
    return this.request(_apiUrl + "GetInstrumentTypesAsync", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getRegionsAndMunicipalitiesAsync() {
    return this.request(_apiUrl + "GetRegionsAndMunicipalitiesAsync", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  //#endregion

  //#region EXPORT

  getInstrumentsExcel(filter) {
    return this.request(_apiUrl + "GetInstrumentsExcel", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(filter),
    });
  }
    
  //#endregion
}

const _instrumentService = new InstrumentService();

export default _instrumentService;
