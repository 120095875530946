import React from "react";
import { Page, View, Document, StyleSheet, Image, Text } from "@react-pdf/renderer";

//Images
import DTGLogo from "../../Global/images/pdfImages/dgt.png";
import SnitLogoHorizontal from "../../Global/images/pdfImages/snitLogo_horizontal.png";
import NorteLogo from "../../Global/images/pdfImages/Norte.jpg";
import A4Landscape from "../../Global/images/pdfImages/A4/Landscape/banner.png";
import A4Portrait from "../../Global/images/pdfImages/A4/Portrait/banner.png";
import A3Landscape from "../../Global/images/pdfImages/A3/Landscape/banner.png";
import A3Portrait from "../../Global/images/pdfImages/A3/Portrait/banner.png";

const StyledPdf = StyleSheet.create({
    page: {
        padding: 16,
        display: "flex",
        justifyContent: "space-between",
    },
    //   HEADER
    header: {
        marginBottom: 8,
    },
    headerTextWrapper: {
        padding: 8,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
    },
    textHeader: {
        fontSize: 10,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
    },
    northWrapper: {
        textAlign: "right",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    northLogo: {
        textAlign: "right",
        height: 28,
        width: 29,
    },
    //   IMAGE
    imageWrapper: {
        width: "100%",
        height: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    imageWrapperDR: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    imagePlan: {
        height: "100%",
        objectFit: "contain",
    },

    //   FOOTER
    footer: {
        marginTop: 8,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
    },
    footerTextWrapper: {
        textAlign: "left",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    footerText: {
        fontSize: 8,
    },
    footerTextSmall: {
        fontSize: 7,
    },
    dgtWrapper: {
        textAlign: "right",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    dgtLogo: {
        textAlign: "right",
        height: 28,
        width: 80,
    },
    SnitLogoHorizontal: {
        textAlign: "right",
        height: "auto",
        width: 80,
        marginBottom: 8,
    },
});

const PdfExport = (data) => (
    <Document>
        {data?.data &&
            data?.data.map((r, index) => {
                return (
                    <Page size={data?.page ?? "A4"} orientation={data?.orientation ?? "portrait"} style={StyledPdf.page} key={`pdfView_${r.original}}`}>
                        {/* HEADER */}
                        {!data?.convert && (
                            <View style={StyledPdf.header}>
                                {/* A4 PORTRAIT */}
                                {data?.page === "A4" && data?.orientation === "portrait" && <Image style={StyledPdf.imgHeader} src={A4Portrait}></Image>}
                                {/* A4 LANDSCAPE */}
                                {data?.page === "A4" && data?.orientation === "landscape" && <Image style={StyledPdf.imgHeader} src={A4Landscape}></Image>}
                                {/* A3 PORTRAIT */}
                                {data?.page === "A3" && data?.orientation === "portrait" && <Image style={StyledPdf.imgHeader} src={A3Portrait}></Image>}
                                {/* A3 LANDSCAPE */}
                                {data?.page === "A3" && data?.orientation === "landscape" && <Image style={StyledPdf.imgHeader} src={A3Landscape}></Image>}
                                <View style={StyledPdf.headerTextWrapper}>
                                    <Text style={StyledPdf.textHeader}>Município de {Array.isArray(data?.municipality) ? data?.municipality.map((m) => m.designation).join(", ") : data?.municipality}</Text>
                                    <Text style={StyledPdf.textHeader}>{data?.designation}</Text>
                                    <Text style={StyledPdf.textHeader}>
                                        {index > 0 ? "Legenda de | " : ""}
                                        {data?.name}
                                    </Text>
                                </View>
                                <View style={StyledPdf.northWrapper}>
                                    <Image style={StyledPdf.northLogo} src={NorteLogo}></Image>
                                </View>
                            </View>
                        )}

                        {/* IMAGE */}
                        {!data?.convert && (
                            <View style={StyledPdf.imageWrapper}>
                                <Image style={StyledPdf.imagePlan} src={`${r.original}`}></Image>
                            </View>
                        )}
                        {data?.convert && (
                            <View style={StyledPdf.imageWrapperDR}>
                                <Image style={StyledPdf.imagePlan} src={`${window.location.origin}/api/Instrument/GetImageAsync?url=${r.original}`}></Image>
                            </View>
                        )}

                        {/* FOOTER */}
                        {!data?.convert && (
                            <View style={StyledPdf.footer}>
                                <View style={StyledPdf.footerTextWrapper}>
                                    <Text style={StyledPdf.footerText}>© DGT 2021 - Todos os direitos reservados</Text>
                                    <Text style={StyledPdf.footerText}>Documento obtido a partir do SNIT Acesso Simples. Não serve para utilização oficial</Text>
                                    <Text style={StyledPdf.footerTextSmall}>Impressão em {new Date().toLocaleDateString()}</Text>
                                </View>
                                <View style={StyledPdf.dgtWrapper}>
                                    <Image style={StyledPdf.SnitLogoHorizontal} src={SnitLogoHorizontal}></Image>
                                    <Image style={StyledPdf.dgtLogo} src={DTGLogo}></Image>
                                    <Text style={StyledPdf.footerTextSmall}>
                                        Página {index + 1}/{data?.data.length}
                                    </Text>
                                </View>
                            </View>
                        )}
                        {/* DGT SYMBOL */}
                    </Page>
                );
            })}
    </Document>
);

export default PdfExport;
