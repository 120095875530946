import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//IMGS
import { ReactComponent as Collapse } from "../../Global/icons/collapseIcon.svg";
import { ReactComponent as Filter } from "../../Global/icons/filterIcon.svg";

//COMPONENTS
import MapSelect from "../../Global/Elements/Forms/MapSelect.js";
import MultipleSelectionDropdown from "../../Global/Elements/Forms/MultipleSelectionDropdown.js";
import InForceOrHistoricSelect from "../../Global/Elements/Forms/InForceOrHistoricSelect.js";
import MainButton from "../../Global/Elements/Buttons/MainButton.js";
import SimpleButton from "../../Global/Elements/Buttons/SimpleButton.js";

//Services
import instrumentService from "./../../../services/InstrumentService.js";

const StyledFilters = styled(NanoFlex)`
  width: ${(props) => (props.isCollapsed ? "48px" : "344px")};
  transition: ${(props) => props.theme.transition};

  .filterWrapper {
    border-radius: 20px 0 0 20px;
    background-color: ${(props) => props.theme.color.main.white};
    overflow: hidden;
    .topWrapper {
      height: ${(props) => (props.isCollapsed ? "100%" : "auto")};
      .headerFilters {
        position: relative;
        height: ${(props) => (props.isCollapsed ? "100%" : "auto")};
        padding: 8px;
        background-color: ${(props) => props.theme.color.main.yellow};
        h5 {
          margin-top: 3px;
          display: ${(props) => (props.isCollapsed ? "none" : "flex")};
          text-transform: uppercase;
        }
        .filterIcon {
          display: ${(props) => (props.isCollapsed ? "flex" : "none")};
          svg {
            width: 22px;
            height: auto;
            path {
              fill: ${(props) => props.theme.color.main.font};
            }
          }
        }
        .collapseBtn {
          width: 25px;
          height: 25px;
          position: absolute;
          top: 6px;
          right: ${(props) => (props.isCollapsed ? "auto" : "6px")};
          border-radius: 60px;
          cursor: pointer;
          svg {
            width: 11px;
            height: auto;
            transform: rotate(${(props) => (props.isCollapsed ? "180deg" : "0")});
            path {
              fill: ${(props) => props.theme.color.main.font};
            }
          }
          &:hover {
            background-color: #ffffff8f;
          }
        }
      }
      .filtersContainer {
        padding: 16px;
        display: ${(props) => (props.isCollapsed ? "none" : "flex")};
        .formWrapper {
          height: auto;
          margin-bottom: 16px;
          .titleForm {
            margin-bottom: 4px;
            text-transform: uppercase;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .bottomWrapper {
      height: auto;
      padding: 16px 16px 32px 16px;
      display: ${(props) => (props.isCollapsed ? "none" : "flex")};
      .simpleButton {
        .simpleButtonWrapper {
          &:hover {
            p {
              color: ${(props) => props.theme.color.main.error};
            }
          }
        }
      }
      .formWrapper {
        margin-bottom: 8px;
        h6 {
          color: ${(props) => props.theme.color.colorsPalette.grey};
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default class Filters extends BaseComponent {
  //#region DEFAULT INSTANCE
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      isCollapsed: JSON.parse(localStorage.getItem("isFiltersCollapsed")) ?? false,
      regionsMunicipalities: [],
      regionsMunicipalitiesSelected: [],
      allRegionsMunicipalitiesSelected: false,
      regionSelected: [], //["REGIÃO AUTÓNOMA DOS AÇORES - GRUPO OCIDENTAL", "REGIÃO AUTÓNOMA DOS AÇORES - GRUPO CENTRAL", "REGIÃO AUTÓNOMA DOS AÇORES - GRUPO ORIENTAL", "REGIÃO AUTÓNOMA DA MADEIRA", "NORTE", "CENTRO", "LISBOA E VALE DO TEJO", "ALENTEJO", "ALGARVE"],
      types: [],
      typesSelected: [],
      allTypesSelected: false,
      statusSelected: 1,
    };
  };

  componentDidMount() {
    this.getAllData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isCollapsed !== prevState.isCollapsed) {
      localStorage.setItem("isFiltersCollapsed", this.state.isCollapsed);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //#endregion

  //#region SERVICE

  getAllData = () => {
    this.getRegionsAndMunicipalities();
    this.getInstrumentTypes();
  };

  getRegionsAndMunicipalities = () => {
    instrumentService
      .getRegionsAndMunicipalitiesAsync()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
        }
      })
      .then((data) => {
        this.setState({ regionsMunicipalities: data });
      });
  };

  getInstrumentTypes = () => {
    instrumentService
      .getInstrumentTypesAsync()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
        }
      })
      .then((data) => {
        this.setState({ types: data });
      });
  };

  //#endregion

  onFilter = () => {
    this.props.onFilterChange({
      regionsMunicipalities: this.state.regionsMunicipalities,
      regionsMunicipalitiesSelected: this.state.regionsMunicipalitiesSelected,
      allRegionsMunicipalitiesSelected: this.state.allRegionsMunicipalitiesSelected,
      typesSelected: this.state.typesSelected,
      allTypesSelected: this.state.allTypesSelected,
      statusSelected: this.state.statusSelected,
    });
  };

  onClear = () => {
    this.setState({
      regionsMunicipalitiesSelected: [],
      allRegionsMunicipalitiesSelected: false,
      typesSelected: [],
      allTypesSelected: false,
      statusSelected: 1,
    });
  };

  toggleCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  render() {
    let municipalities = this.state.regionsMunicipalities
      .filter((f) => this.state.regionSelected.includes(f.name))
      .map((m) => m.listMunicipalities)
      .flat()
      .map((s) => {
        return { id: s.dtcc, name: s.designation };
      });
    let types = this.state.types.map((m) => {
      return { id: m.idtype, name: m.shortname + " (" + m.name + ")" };
    });

    return (
      <StyledFilters isCollapsed={this.state.isCollapsed}>
        <NanoFlex className="filterWrapper" flexDirection="column" justifyContent="space-between">
          <NanoFlex className="topWrapper" flexDirection="column">
            <NanoFlex className="headerFilters">
              <Type.h5>
                <b>{this.translation.global.filters}</b>
              </Type.h5>
              <NanoFlex className="filterIcon">
                <Filter />
              </NanoFlex>
              <NanoFlex className="collapseBtn" onClick={this.toggleCollapse}>
                <Collapse />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="filtersContainer" flexDirection="column" justifyContent="flex-start">
              {/* MAP SELECT */}
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <MapSelect title={this.translation.filters.region} selectedRegions={this.state.regionSelected} onSelecteRegionsChange={(e) => this.setState({ regionSelected: e })} />
              </NanoFlex>
              {/* COUNTY */}
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <Type.p className="titleForm">
                  <b>{this.translation.filters.county}</b>
                </Type.p>
                <MultipleSelectionDropdown
                  placeholder={this.translation.filters.selectOption}
                  options={municipalities}
                  selected={this.state.regionsMunicipalitiesSelected}
                  onChangeSelection={(e) => this.setState({ allRegionsMunicipalitiesSelected: e.isAllSelected, regionsMunicipalitiesSelected: e.selected.map((i) => i.id) })}
                />
              </NanoFlex>
              {/* PLAN TYPE */}
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <Type.p className="titleForm">
                  <b>{this.translation.filters.planType}</b>
                </Type.p>
                <InForceOrHistoricSelect selected={this.state.statusSelected} onSelecteStateChange={(e) => this.setState({ statusSelected: e })} />
                {this.props.plans && <MultipleSelectionDropdown placeholder={this.translation.filters.selectOption} options={types} selected={this.state.typesSelected} onChangeSelection={(e) => this.setState({ allTypesSelected: e.isAllSelected, typesSelected: e.selected.map((i) => i.id) })} />}
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="bottomWrapper" flexDirection="column">
            <NanoFlex className="btnContainer" flexDirection="column">
              <NanoFlex className="formWrapper" alignItems="flex-start">
                <SimpleButton label={this.translation.filters.cleanFilters} clickAction={this.onClear} />
              </NanoFlex>
              <NanoFlex className="formWrapper" alignItems="flex-start">
                <MainButton label={this.translation.filters.search} disabled={this.state.regionsMunicipalitiesSelected?.length === 0 || (this.props.plans && this.state.typesSelected.length === 0)} clickAction={this.onFilter} />
              </NanoFlex>
              <NanoFlex className="formWrapper" alignItems="flex-start">
                <Type.h6>{this.translation.filters.messageRequired}</Type.h6>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledFilters>
    );
  }
}
