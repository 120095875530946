import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//IMAGES
import FooterLogos from "../../Global/images/logosfunds.png";

//COMPONENTS
import SimpleButton from "../../Global/Elements/Buttons/SimpleButton.js";
import Modal from "../../Global/Elements/Modals/Modal.js";
import { ContactModal } from "../../Global/Elements/Modals/ContactModal.js";


const StyledFooter = styled(NanoFlex)`
  height: auto;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  background-color: ${(props) => props.theme.color.main.white};
  padding: 6px 32px;
  .footerBlock {
      width: auto;
      img {
          max-height: 26px;
          width: auto;
          object-fit: contain;
      }
  }
`;

export class Footer extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      openContactModal: false,
    };
  }
    componentDidMount() {
        super.componentDidMount();
      }
    
      componentWillUnmount() {
        super.componentWillUnmount();
      }
  render() {
    return (
      <StyledFooter>
        <NanoFlex className="footerWrapper" justifyContent="space-between">
          <NanoFlex className="footerBlock">
              <Type.h6>Direção-Geral do Território © 2021</Type.h6>
          </NanoFlex>
          <NanoFlex className="footerBlock">
          <img src={FooterLogos} alt="Confinaciado por..." />
          </NanoFlex>
          <NanoFlex className="footerBlock">
             <SimpleButton label={this.translation.global.contactUs} clickAction={() => this.setState({ openContactModal: true })}/>
             <Modal show={this.state.openContactModal} content={<ContactModal cancelAction={() => this.setState({ openContactModal: false })} nextAction={() => this.setState({ openContactModal: false })} />} />

          </NanoFlex>
        </NanoFlex>
      </StyledFooter>
    );
  }
}
