import React from "react";
import styled from "styled-components";
import BaseComponent from "../../../BaseComponent.js";
import NanoFlex from "../../../Global/NanoFlex.js";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/scss/image-gallery.scss";


const StyledImagesViewer = styled(NanoFlex)`
  height: auto;
`;

const images = [
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
];

export class ImagesViewer extends BaseComponent {

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledImagesViewer>
          <ImageGallery items={images} thumbnailPosition="right" showPlayButton={false}/>
      </StyledImagesViewer>
    );
  }
}
