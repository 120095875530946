import { BaseService } from "./BaseService";

const _apiUrl = "api/UserInteractions/";

export class UserInteractionsService extends BaseService {
  sendContact(contact) {
    return this.request(_apiUrl + "SendContact", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(contact),
    });
  }

  submitFeedback(feedback) {
    return this.request(_apiUrl + "SubmitFeedback", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(feedback),
    });
  }
}

const _userInteractionsService = new UserInteractionsService();

export default _userInteractionsService;
