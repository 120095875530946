import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

// IMAGES
import { ReactComponent as FeedbackIcon } from "../../icons/feedbackIcon.svg";

//COMPONENTS
import SimpleButton from "./SimpleButton.js";

const StyledFeedbackButton = styled(NanoFlex)`
  height: auto;
  width: auto;
  box-shadow: ${(props) => props.theme.boxShadow};
  background-color: ${(props) => props.theme.color.main.white};
  padding: 8px 16px;
  border-radius: 0 0 20px 20px;
  cursor: pointer;
  transition: ${(props) => props.theme.transition};
  user-select: none;
  .feedbackBtnWrapper {
    width: auto;
    height: auto;

    .iconWrapper {
      width: auto;
    }
  }
  &:hover {
    transition: ${(props) => props.theme.transition};
    opacity: .7;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
`;

export default class FeedbackButton extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledFeedbackButton>
        <NanoFlex className="feedbackBtnWrapper" onClick={() => this.props.clickAction(this.props.id)}>
          <NanoFlex className="iconWrapper">
            <FeedbackIcon />
          </NanoFlex>
          <NanoFlex className="titleWrapper">
            <SimpleButton label={this.translation.global.giveUsFeedback} />
          </NanoFlex>
        </NanoFlex>
      </StyledFeedbackButton>
    );
  }
}
