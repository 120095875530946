/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";
import { Popper, Reference, Manager } from "react-popper";
import { NavLink } from "react-router-dom";
//IMGS
import { ReactComponent as InfoIcon } from "../../Global/icons/infoIcon.svg";
import { ReactComponent as DownloadIcon } from "../../Global/icons/downloadIcon.svg";
import { ReactComponent as MapIcon } from "../../Global/icons/mapIcon.svg";
import { ReactComponent as ArrowUp } from "../../Global/icons/arrowUp.svg";
import { ReactComponent as ArrowDown } from "../../Global/icons/arrowDown.svg";
import { ReactComponent as HistoricIcon } from "../../Global/icons/historicIcon.svg";
import { ReactComponent as InForceIcon } from "../../Global/icons/inForceIcon.svg";
//COMPONENTS
import PrintButton from "../../Global/Elements/Buttons/PrintButton.js";
import SortBy from "../../Global/Elements/Buttons/SortBy.js";
import { downloadBlob } from "./../../Global/tools.js";
import Loading from "./../../Global/Elements/Loading/Loading.js";
//SERVICES
import easementService from "./../../../services/EasementService.js";

const StyledTable = styled(NanoFlex)`
  padding: 16px 16px 32px 16px;
  position: relative;
  height: calc(100vh - 183px);
  .tableWrapper {
    .topTableContainer {
      height: auto;
      margin-bottom: 8px;
      .asideInfo {
        height: auto;
      }
    }
    .tableContainer {
      border: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
      height: calc(100% - 42px);
      .tableHeader {
        height: auto;
        padding: 4px 16px 4px 56px;
        background-color: ${(props) => props.theme.color.colorsPalette.opacityGrey};
        border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
        .itemHeader {
          margin-right: 4px;
          padding: 8px;
          cursor: pointer;
          user-select: none;
          p {
            text-transform: uppercase;
          }
          .sortWrapper {
            margin-left: 8px;
            width: auto;
            height: auto;
          }
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
          }
        }
        .iconItem {
          width: auto;
          min-width: 32px;
          pointer-events: none;
        }
      }
      .table {
        /* overflow: auto; */
        overflow: overlay;
        .linesWrapper {
          height: auto;
        }
        .line {
          height: auto;
          border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
          padding-right: 16px;
          .itemLine {
            padding: 8px;
            margin-right: 4px;
            width: 100%;
            overflow: hidden;
            p {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              /* white-space: nowrap; */
            }
            .moreInfoLine {
              height: auto;
              width: auto;
              background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
              border-radius: 60px;
              padding: 4px;
              cursor: pointer;
              margin-left: 4px;
              p {
                font-size: 10px;
              }
            }
            .infoContainer {
              width: auto;
              height: auto;
              margin-top: 16px;
              background-color: ${(props) => props.theme.color.main.white};
              border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
              box-shadow: ${(props) => props.theme.boxShadow};
              padding: 8px;
              p {
                overflow: unset;
                white-space: unset;
                margin-bottom: 4px;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
          .expandItem {
            cursor: pointer;
            width: 26px;
            overflow: inherit;
            svg {
              width: 12px;
              height: auto;
              path {
                fill: ${(props) => props.theme.color.main.ren};
              }
            }
            &:hover {
              background-color: ${(props) => props.theme.color.colorsPalette.opacityGrey};
            }
          }
          .expandSpace {
            /* width: 100px; */
            min-width: 26px;
            padding: 0;
            width: 26px;
            background-color: ${(props) => props.theme.color.colorsPalette.opacityGrey};
          }
          .infoLine {
            svg {
              height: 16px;
              width: auto;
              cursor: pointer;
              path {
                fill: ${(props) => props.theme.color.main.ren};
              }
              /* &:hover {
                ~ .infoContainer {
                  display: flex;
                }
              } */
            }
            .infoContainer {
              width: 360px;
              height: auto;
              margin-top: 20px;
              background-color: ${(props) => props.theme.color.main.white};
              border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
              box-shadow: ${(props) => props.theme.boxShadow};
              padding: 8px;
              p {
                overflow: unset;
                white-space: unset;
              }
            }
            &:hover {
              overflow: unset;
            }
          }

          .planTypeItem {
            width: 26px;
            padding: 4px;
            overflow: inherit;
            svg {
              height: 14px;
              width: auto;
            }
          }
          .historicItem {
            svg {
              path {
                fill: ${(props) => props.theme.color.colorsPalette.historic};
              }
            }
            .historicIcon {
              display: flex;
            }
            .inForceIcon {
              display: none;
            }
          }
          .inForceItem {
            svg {
              path {
                fill: ${(props) => props.theme.color.colorsPalette.inForce};
              }
            }
            .historicIcon {
              display: none;
            }
            .inForceIcon {
              display: flex;
            }
          }
          .btnIconItem {
            min-width: 32px;
            width: auto;
            overflow: inherit;
            cursor: pointer;
            svg {
              path {
                fill: ${(props) => props.theme.color.main.font};
              }
            }
            &:hover {
              opacity: 0.7;
            }
          }
          .mapItem {
            svg {
              path {
                fill: ${(props) => props.theme.color.main.ren};
              }
            }
          }
          .linkLine {
            a {
              text-decoration: none;
              p {
                color: ${(props) => props.theme.color.main.ren};
              }
              &:hover {
                p {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .lineExpandedColor {
          background-color: ${(props) => props.theme.color.colorsPalette.opacityGrey};
          .expandSpace {
            background-color: unset;
          }
        }
        .expandendLine {
          .expandItem {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        .subHeader {
          height: auto;
          padding: 4px 16px 4px 56px;
          background-color: ${(props) => props.theme.color.colorsPalette.opacityGrey};
          .itemHeader {
            margin-right: 4px;
            padding: 8px;
            /* cursor: pointer; */
            user-select: none;
            p {
              text-transform: uppercase;
            }
            .sortWrapper {
              margin-left: 8px;
              width: auto;
              height: auto;
            }
            &:last-child {
              margin-right: 0;
            }
            /* &:hover {
              background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
            } */
          }
          .iconItem {
            width: auto;
            min-width: 32px;
            pointer-events: none;
          }
        }
      }
      .noResults {
        padding: 32px;
        h3 {
          text-transform: uppercase;
          color: ${(props) => props.theme.color.colorsPalette.lightGrey};
        }
      }
    }
    .small {
      min-width: 100px;
      flex: 3;
    }
    .medium {
      min-width: 160px;
      flex: 2;
    }
    .empty {
      pointer-events: none;
    }
  }
  .infoWrapper {
    position: absolute;
    bottom: 8px;
    right: 8px;
    height: auto;
    width: auto;
    transition: ${(props) => props.theme.transition};
    .iconWrapper {
      height: auto;
      width: auto;
      cursor: pointer;
      svg {
        height: 16px;
        width: auto;
        path {
          fill: ${(props) => props.theme.color.colorsPalette.lightGrey};
        }
      }
    }
    .infoContainer {
      display: none;
      position: absolute;
      width: 360px;
      height: auto;
      right: 4px;
      bottom: 20px;
      background-color: ${(props) => props.theme.color.main.white};
      border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
      box-shadow: ${(props) => props.theme.boxShadow};
      padding: 8px;
    }
    &:hover {
      transition: ${(props) => props.theme.transition};
      .iconWrapper {
        svg {
          path {
            fill: ${(props) => props.theme.color.colorsPalette.grey};
          }
        }
      }
      .infoContainer {
        display: flex;
      }
    }
  }
`;

export default class Table extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            openInfoContainerId: null,
            collapsedLines: this.props.results?.map((m) => m.identifier) ?? [],
            orderBy: "",
            orderByDirection: "",
            loading: false,
        };

        this.tableContent = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    expandCollapse(id) {
        if (this.state.collapsedLines.includes(id)) {
            var index = this.state.collapsedLines.indexOf(id);
            if (index !== -1) {
                this.state.collapsedLines.splice(index, 1);
            }
            this.setState({ collapsedLines: this.state.collapsedLines });
        } else {
            this.setState({ collapsedLines: [...this.state.collapsedLines, id] });
        }
    }

    sort(columnName) {
        if (this.state.orderBy === columnName) {
            if (this.state.orderByDirection === "ASC") {
                this.setState({ orderBy: columnName, orderByDirection: "DESC" });
            } else {
                this.setState({ orderBy: "", orderByDirection: "" });
            }
        } else {
            this.setState({ orderBy: columnName, orderByDirection: "ASC" });
        }
    }

    print(filter) {
        this.setState({ loading: true }, () => {
            easementService
                .getEasementsExcel(filter)
                .then((response) => {
                    if (response.ok) {
                        return response.blob();
                    } else {
                        // Catch
                        if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
                    }
                })
                .then((blob) => {
                    this.setState({ loading: false });
                    if (blob) {
                        downloadBlob(blob, "SNITAcessoSimples.xlsx");
                    }
                });
        });
    }

    redirectPlans(data, converData) {
        //SET DATA
        sessionStorage.setItem("rasters", data);
        //NAVIGATE TO PAGE
        window.open("/plans", "_blank");
    }

    download(uris, name) {
        uris.forEach((uri) => {
            const link = document.createElement("a");
            link.href = uri;
            link.download = name;
            link.target = "_bank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    render() {
        let results = this.props.results?.sort((a, b) =>
            this.state.orderByDirection === "ASC" ? (a[this.state.orderBy] < b[this.state.orderBy] ? -1 : a[this.state.orderBy] > b[this.state.orderBy] ? 1 : 0) : (a[this.state.orderBy] < b[this.state.orderBy] ? -1 : a[this.state.orderBy] > b[this.state.orderBy] ? 1 : 0) * -1
        );
        //let regions = this.props.regionsMunicipalities?.flatMap(({ name, listMunicipalities }) => listMunicipalities.flatMap((x) => ({ name, ...x })));
        let loading = this.state.loading;

        return (
            <StyledTable igt={this.props.igt} ren={this.props.ren} ref={this.tableContent}>
                {loading && (
                    <NanoFlex className="loadingWrapper">
                        <Loading />
                    </NanoFlex>
                )}
                {!loading && (
                    <NanoFlex className="tableWrapper" flexDirection="column" justifyContent="flex-start">
                        <NanoFlex className="topTableContainer">
                            {results && (
                                <NanoFlex className="asideInfo" justifyContent="flex-start">
                                    <Type.p>{results !== null ? results?.length + " " + this.translation.global.results : ""}</Type.p>
                                </NanoFlex>
                            )}
                            <NanoFlex className="asideInfo" justifyContent="flex-end">
                                <PrintButton renColor clickAction={() => this.print(this.props.filterSelected)} />
                            </NanoFlex>
                        </NanoFlex>
                        <NanoFlex className="tableContainer" flexDirection="column" justifyContent="flex-start">
                            <NanoFlex className="tableHeader">
                                {/* ITEM HEADER */}
                                <NanoFlex className="itemHeader" justifyContent="space-between" onClick={() => this.sort("description")}>
                                    <Type.p>
                                        <b>{this.translation.filters.servitude}</b>
                                    </Type.p>
                                    <NanoFlex className="sortWrapper">
                                        <SortBy sortUp={this.state.orderBy === "description" && this.state.orderByDirection === "ASC" ? true : false} sortDown={this.state.orderBy === "description" && this.state.orderByDirection === "DESC" ? true : false} />
                                    </NanoFlex>
                                </NanoFlex>
                                {/* ITEM HEADER */}
                                <NanoFlex className="itemHeader" justifyContent="space-between" onClick={() => this.sort("description")}>
                                    <Type.p>
                                        <b>{this.translation.filters.designation}</b>
                                    </Type.p>
                                    <NanoFlex className="sortWrapper">
                                        <SortBy sortUp={this.state.orderBy === "description" && this.state.orderByDirection === "ASC" ? true : false} sortDown={this.state.orderBy === "description" && this.state.orderByDirection === "DESC" ? true : false} />
                                    </NanoFlex>
                                </NanoFlex>
                                {/* ITEM HEADER */}
                                <NanoFlex className="itemHeader" justifyContent="space-between" onClick={() => this.sort("region")}>
                                    <Type.p>
                                        <b>{this.translation.filters.county}</b>
                                    </Type.p>
                                    <NanoFlex className="sortWrapper">
                                        <SortBy sortUp={this.state.orderBy === "region" && this.state.orderByDirection === "ASC" ? true : false} sortDown={this.state.orderBy === "region" && this.state.orderByDirection === "DESC" ? true : false} />
                                    </NanoFlex>
                                </NanoFlex>

                                {results && this.state.collapsedLines?.length !== results?.filter((f) => f.listDynamic.length > 0).length && (
                                    <NanoFlex className="itemHeader" justifyContent="space-between">
                                        <Type.p>
                                            <b>{this.translation.filters.diploma}</b>
                                        </Type.p>
                                        <NanoFlex className="sortWrapper">
                                            <SortBy sortUp={false} sortDown={false} />
                                        </NanoFlex>
                                    </NanoFlex>
                                )}
                                {results && this.state.collapsedLines?.length !== results?.filter((f) => f.listDynamic.length > 0).length && (
                                    <NanoFlex className="itemHeader medium" justifyContent="space-between">
                                        <Type.p>
                                            <b>{this.translation.filters.DR}</b>
                                        </Type.p>
                                        <NanoFlex className="sortWrapper">
                                            <SortBy sortUp={false} sortDown={false} />
                                        </NanoFlex>
                                    </NanoFlex>
                                )}
                                {results && this.state.collapsedLines?.length !== results?.filter((f) => f.listDynamic.length > 0).length && (
                                    <NanoFlex className="itemHeader small" justifyContent="space-between" onClick={() => this.sort("pubDate")}>
                                        <Type.p>
                                            <b>{this.translation.filters.date}</b>
                                        </Type.p>
                                        <NanoFlex className="sortWrapper">
                                            <SortBy sortUp={this.state.orderBy === "pubDate" && this.state.orderByDirection === "ASC" ? true : false} sortDown={this.state.orderBy === "pubDate" && this.state.orderByDirection === "DESC" ? true : false} />
                                        </NanoFlex>
                                    </NanoFlex>
                                )}
                                {/* ITEM HEADER */}
                                {results && this.state.collapsedLines?.length !== results?.filter((f) => f.listDynamic.length > 0).length && (
                                    <NanoFlex className="itemHeader iconItem">
                                        <DownloadIcon />
                                    </NanoFlex>
                                )}
                                {(!results || this.state.collapsedLines?.length === results?.filter((f) => f.listDynamic.length > 0).length) && <NanoFlex className="itemHeader empty">{/* EMPTY */}</NanoFlex>}
                                {(!results || this.state.collapsedLines?.length === results?.filter((f) => f.listDynamic.length > 0).length) && <NanoFlex className="itemHeader empty medium">{/* EMPTY */}</NanoFlex>}
                                {(!results || this.state.collapsedLines?.length === results?.filter((f) => f.listDynamic.length > 0).length) && <NanoFlex className="itemHeader empty small">{/* EMPTY */}</NanoFlex>}
                                {(!results || this.state.collapsedLines?.length === results?.filter((f) => f.listDynamic.length > 0).length) && <NanoFlex className="itemHeader iconItem">{/* EMPTY */}</NanoFlex>}

                                {/* ITEM HEADER */}
                                <NanoFlex className="itemHeader iconItem">
                                    <MapIcon />
                                </NanoFlex>
                            </NanoFlex>
                            {results && results?.length > 0 && (
                                <NanoFlex className="table" flexDirection="column" justifyContent="flex-start">
                                    {results &&
                                        results.map((r) => {
                                            if (r.listDynamic.length > 0) {
                                                //HAS SUBITEMS
                                                return (
                                                    <NanoFlex className="linesWrapper" key={`instrumentList_${r.identifier}}`} flexDirection="column">
                                                        <NanoFlex className="line expandendLine" justifyContent="flex-start">
                                                            <NanoFlex
                                                                className="itemLine expandItem"
                                                                onClick={() => {
                                                                    this.expandCollapse(r.identifier);
                                                                }}>
                                                                {this.state.collapsedLines && this.state.collapsedLines.includes(r.identifier) && <ArrowUp />}
                                                                {this.state.collapsedLines && !this.state.collapsedLines.includes(r.identifier) && <ArrowDown />}
                                                            </NanoFlex>
                                                            <NanoFlex className={`itemLine planTypeItem ${r.status === 1 ? "inForceItem" : "historicItem"}`}>
                                                                <HistoricIcon className="historicIcon" />
                                                                <InForceIcon className="inForceIcon" />
                                                            </NanoFlex>
                                                            <NanoFlex className="itemLine linkLine" justifyContent="flex-start">
                                                                <a href={r.documentURL} rel="noopener noreferrer" target="_blank">
                                                                    <Type.p title={r.description}>
                                                                        <b>{r.description}</b>
                                                                    </Type.p>
                                                                </a>
                                                            </NanoFlex>
                                                            <NanoFlex className="itemLine" justifyContent="flex-start">
                                                                <Type.p title={r.description}>{r.description}</Type.p>
                                                            </NanoFlex>
                                                            <NanoFlex className="itemLine" justifyContent="flex-start">
                                                                <Type.p title={r.municipality}>{r.municipality}</Type.p>
                                                            </NanoFlex>
                                                            <NanoFlex className="itemLine"></NanoFlex>
                                                            <NanoFlex className="itemLine medium"></NanoFlex>
                                                            <NanoFlex className="itemLine small"></NanoFlex>
                                                            <NanoFlex className="itemLine btnIconItem">{/* <DownloadIcon /> */}</NanoFlex>
                                                            {r.listRasters?.length > 0 && (
                                                                <NanoFlex
                                                                    className="itemLine btnIconItem mapItem"
                                                                    onClick={() =>
                                                                        this.redirectPlans(
                                                                            JSON.stringify({
                                                                                data: r.listRasters,
                                                                                convert: false,
                                                                                designation: r.description,
                                                                                location: [
                                                                                    [0, 0],
                                                                                    [0, 0],
                                                                                ],
                                                                                municipality: r.municipality,
                                                                            })
                                                                        )
                                                                    }>
                                                                    <MapIcon />
                                                                </NanoFlex>
                                                            )}
                                                            {!r.listRasters?.length > 0 && <NanoFlex className="itemLine btnIconItem mapItem"></NanoFlex>}
                                                        </NanoFlex>

                                                        {this.state.collapsedLines &&
                                                            !this.state.collapsedLines.includes(r.identifier) &&
                                                            results &&
                                                            r.listDynamic.map((d) => {
                                                                return (
                                                                    <NanoFlex className="line lineExpandedColor" justifyContent="flex-start" key={`instrumentListSubItems_${d.id}}`}>
                                                                        <NanoFlex className="itemLine expandSpace">{/* <ArrowUp /> */}</NanoFlex>
                                                                        <NanoFlex className={`itemLine planTypeItem ${d.status === 1 ? "inForceItem" : "historicItem"}`}>
                                                                            <HistoricIcon className="historicIcon" />
                                                                            <InForceIcon className="inForceIcon" />
                                                                        </NanoFlex>
                                                                        {/* ITEM LINE */}
                                                                        <NanoFlex className="itemLine linkLine" justifyContent="flex-start">
                                                                            {/* <a href={d.dinLink} rel="noopener noreferrer" target="_blank">
                                        <Type.p title={d.pubDR}>
                                          <b>{d.pubDR}</b>
                                        </Type.p>
                                      </a> */}
                                                                            <Type.p title={d.designation}>{d.designation}</Type.p>
                                                                        </NanoFlex>
                                                                        {/* ITEM LINE */}
                                                                        <NanoFlex className="itemLine" justifyContent="flex-start">
                                                                            <Type.p title={d.condition}>{d.condition}</Type.p>{" "}
                                                                        </NanoFlex>
                                                                        {/* ITEM LINE */}
                                                                        <NanoFlex className="itemLine" justifyContent="flex-start">
                                                                            <Type.p title={r.municipality}>{r.municipality}</Type.p>
                                                                            {/* <Manager>
                                        <Reference>
                                          {(targetProps) => {
                                            return (
                                              <NanoFlex className="moreInfoLine" ref={targetProps.ref} onMouseEnter={() => this.setState({ test: 1 })} onFocus={() => this.setState({ test: 1 })} onMouseLeave={() => this.setState({ test: null })} onBlur={() => this.setState({ test: null })}>
                                                <Type.p>+3</Type.p>
                                              </NanoFlex>
                                            );
                                          }}
                                        </Reference>
                                        {this.state.test === 1 && (
                                          <Popper placement={"bottom"}>
                                            {({ ref, style, placement }) => (
                                              <NanoFlex className="infoContainer" flexDirection="column" ref={ref} style={style} data-placement={placement}>
                                                <Type.p>Faro</Type.p>
                                                <Type.p>Loulé</Type.p>
                                                <Type.p>Albufeira</Type.p>
                                              </NanoFlex>
                                            )}
                                          </Popper>
                                        )}
                                      </Manager> */}
                                                                        </NanoFlex>

                                                                        {/* ITEM LINE */}
                                                                        <NanoFlex className="itemLine linkLine" justifyContent="flex-start">
                                                                            <a href={d.dinLink} rel="noopener noreferrer" target="_blank">
                                                                                <Type.p title={d.pubDR}>
                                                                                    <b>{d.pubDR}</b>
                                                                                </Type.p>
                                                                            </a>
                                                                        </NanoFlex>
                                                                        {/* ITEM LINE */}
                                                                        <NanoFlex className="itemLine medium" justifyContent="flex-start">
                                                                            <Type.p title={d.nrDR}>{d.nrDR}</Type.p>
                                                                        </NanoFlex>
                                                                        {/* ITEM LINE */}
                                                                        <NanoFlex className="itemLine small" justifyContent="flex-start">
                                                                            <Type.p title={new Date(d.pubDate).toLocaleDateString()}>{new Date(d.pubDate).toLocaleDateString()}</Type.p>
                                                                        </NanoFlex>
                                                                        {/* ITEM LINE - DOWNLOAD ITEM*/}
                                                                        <NanoFlex className="itemLine btnIconItem">
                                                                            {d.listElements?.length > 0 && (
                                                                                <DownloadIcon
                                                                                    onClick={() =>
                                                                                        this.download(
                                                                                            d.listElements.map((m) => m.fileJSON[0].fileURL).filter((v, i, a) => a.indexOf(v) === i),
                                                                                            d.designation
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </NanoFlex>
                                                                        {d.listElements?.length > 0 && d.status === 1 && (
                                                                            <NanoFlex
                                                                                className="itemLine btnIconItem mapItem"
                                                                                onClick={() =>
                                                                                    this.redirectPlans(
                                                                                        JSON.stringify({
                                                                                            data: d.listElements,
                                                                                            convert: true,
                                                                                            designation: d.designation,
                                                                                            location: [
                                                                                                [0, 0],
                                                                                                [0, 0],
                                                                                            ],
                                                                                            municipality: r.municipality,
                                                                                        }),
                                                                                        true
                                                                                    )
                                                                                }>
                                                                                <MapIcon />
                                                                            </NanoFlex>
                                                                        )}
                                                                        {!d.listElements?.length > 0 && <NanoFlex className="itemLine btnIconItem mapItem"></NanoFlex>}
                                                                    </NanoFlex>
                                                                );
                                                            })}
                                                    </NanoFlex>
                                                );
                                            } else {
                                                return (
                                                    <NanoFlex className="line" justifyContent="flex-start">
                                                        {/* ITEM LINE - EXPAND ITEM */}
                                                        <NanoFlex className="itemLine expandSpace">{/* <ArrowUp /> */}</NanoFlex>
                                                        {/* ITEM LINE - PLAN TYPE ITEM*/}
                                                        {/* change between "inForceItem" and "historicItem" */}
                                                        <NanoFlex className={`itemLine planTypeItem ${r.status === 1 ? "historicItem" : "inForceItem"}`}>
                                                            <HistoricIcon className="historicIcon" />
                                                            <InForceIcon className="inForceIcon" />
                                                        </NanoFlex>
                                                        {/* ITEM LINE */}
                                                        <NanoFlex className="itemLine linkLine" justifyContent="flex-start">
                                                            <a href={r.documentURL} rel="noopener noreferrer" target="_blank">
                                                                <Type.p title={r.description}>
                                                                    <b>{r.description}</b>
                                                                </Type.p>
                                                            </a>
                                                        </NanoFlex>
                                                        <NanoFlex className="itemLine" justifyContent="flex-start">
                                                            <Type.p title={r.description}>{r.description}</Type.p>
                                                        </NanoFlex>
                                                        <NanoFlex className="itemLine" justifyContent="flex-start">
                                                            <Type.p title={r.municipality}>{r.municipality}</Type.p>
                                                        </NanoFlex>
                                                        {/* ITEM LINE - EMPTY */}
                                                        <NanoFlex className="itemLine">{/* EMPTY */}</NanoFlex>
                                                        {/* ITEM LINE - EMPTY */}
                                                        <NanoFlex className="itemLine medium">{/* EMPTY */}</NanoFlex>
                                                        {/* ITEM LINE - EMPTY */}
                                                        <NanoFlex className="itemLine small">{/* EMPTY */}</NanoFlex>
                                                        {/* ITEM LINE - DOWNLOAD ITEM*/}
                                                        <NanoFlex className="itemLine btnIconItem">{/* <DownloadIcon /> */}</NanoFlex>
                                                        {/* ITEM LINE - MAP ITEM*/}
                                                        <NanoFlex className="itemLine btnIconItem mapItem">
                                                            <MapIcon />
                                                        </NanoFlex>
                                                    </NanoFlex>
                                                );
                                            }
                                        })}
                                </NanoFlex>
                            )}

                            {/* NO RESULTS - REMOVE STYLE INLINE */}
                            {(!results || results.length === 0) && (
                                <NanoFlex className="noResults">
                                    <Type.h3>
                                        <b>Sem resultados encontrados</b>
                                    </Type.h3>
                                </NanoFlex>
                            )}
                        </NanoFlex>
                    </NanoFlex>
                )}
                <NanoFlex className="infoWrapper" justifyContent="flex-end">
                    <NanoFlex className="iconWrapper">
                        <InfoIcon />
                    </NanoFlex>
                    <NanoFlex className="infoContainer">
                        <Type.p>{this.translation.global.renInfo}</Type.p>
                    </NanoFlex>
                </NanoFlex>
            </StyledTable>
        );
    }
}
