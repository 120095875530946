import React from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";

//IMGS
import { ReactComponent as SortUp } from "../../icons/arrowUp.svg";
import { ReactComponent as SortDown } from "../../icons/arrowDown.svg";

const StyledSortBy = styled(NanoFlex)`
  height: auto;
  width: auto;
  cursor: pointer;
  .sortByWrapper {
    width: auto;
    margin-bottom: 2px;
    svg {
      width: 6px;
      height: auto;

      path {
        fill: ${(props) => props.theme.color.colorsPalette.grey};
      }
    }
    .sortUpIcon {
      path {
        fill: ${(props) => (props.sortUp ? props.theme.color.main.font : props.sortDown ? props.theme.color.colorsPalette.grey : props.theme.color.colorsPalette.font)};
      }
    }
    .sortDownIcon {
      path {
        fill: ${(props) => (props.sortDown ? props.theme.color.main.font : props.sortUp ? props.theme.color.colorsPalette.grey : props.theme.color.colorsPalette.font)};
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

`;

const SortBy = (props) => {
  return (
    <StyledSortBy className="sortBy" flexDirection="column" sortUp={props.sortUp} sortDown={props.sortDown}>
      <NanoFlex className="sortByWrapper ">
        <SortUp className="sortUpIcon" />
      </NanoFlex>
      <NanoFlex className="sortByWrapper">
        <SortDown className="sortDownIcon" />
      </NanoFlex>
    </StyledSortBy>
  );
};

export default SortBy;
