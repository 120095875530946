import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

const StyledFormatSwitchButton = styled(NanoFlex)`
  height: auto;
  width: auto;
  .itemOption {
    .textWrapper {
      padding: 6px;
      border-radius: 60px;
      background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .a4Format {
      background-color: ${(props) => (props.a4Format ? props.theme.color.colorsPalette.darkBlue : props.theme.color.colorsPalette.lighterGrey)};
      pointer-events: ${(props) => (props.a4Format ? "none" : "default")};
      p {
        color: ${(props) => (props.a4Format ? props.theme.color.main.white : props.theme.color.main.font)};
        font-weight: ${(props) => (props.a4Format ? "bold" : "normal")};
      }
    }
    .a3Format {
      background-color: ${(props) => (props.a3Format ? props.theme.color.colorsPalette.darkBlue : props.theme.color.colorsPalette.lighterGrey)};
      pointer-events: ${(props) => (props.a3Format ? "none" : "default")};
      p {
        color: ${(props) => (props.a3Format ? props.theme.color.main.white : props.theme.color.main.font)};
        font-weight: ${(props) => (props.a3Format ? "bold" : "normal")};
      }
    }
  }
`;

export default class FormatSwitchButton extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledFormatSwitchButton className="formatType" a4Format={this.props.selected === 1} a3Format={this.props.selected === 2}>
        <NanoFlex className="itemOption" onClick={() => this.props.onSelectedFormatChange(1)}>
          <NanoFlex className="textWrapper a4Format">
            <Type.p>A4</Type.p>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="itemOption" onClick={() => this.props.onSelectedFormatChange(2)}>
          <NanoFlex className="textWrapper a3Format">
            <Type.p>A3</Type.p>
          </NanoFlex>
        </NanoFlex>
      </StyledFormatSwitchButton>
    );
  }
}
