import React, { Component } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import RenderRoute from "./components/Global/RenderRoute";

// Themes 
import defaultTheme from "./themes/default.json";

// Components
import { Layout } from "./components/views/Shared/Layout.js";
import { Home } from "./components/views/Home/Home.js";
import { IGT } from "./components/views/IGT/IGT.js";
import { Plans } from "./components/views/Plans/Plans.js";
import { REN } from "./components/views/REN/REN.js";

//Guidelines
import { Guidelines } from "./components/views/Guidelines/Guidelines.js";

//Tests
import { ImagesViewer } from "./components/views/Tests/ImagesViewer/ImagesViewer.js";


//STYLES
import "./styles/reset.scss";
import "./styles/config.scss";

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <Switch>
             {/* Home */}
             <RenderRoute exact path="/">
              <Layout>
                <Home />
              </Layout>
            </RenderRoute>
            {/* IGT */}
            <RenderRoute exact path="/igt">
              <Layout>
                <IGT />
              </Layout>
            </RenderRoute>
             {/* REN */}
             <RenderRoute exact path="/ren">
              <Layout>
                <REN />
              </Layout>
            </RenderRoute>
            {/* PLANS */}
            <RenderRoute exact path="/plans">
              <Layout>
                <Plans />
              </Layout>
            </RenderRoute>
            {/* GUIDELINES */}
            {window.location.hostname === "localhost" && (
              <RenderRoute exact path="/guidelines">
                <Guidelines />
              </RenderRoute>
            )}
            {/* IMAGES GALERY */}
            {window.location.hostname === "localhost" && (
              <RenderRoute exact path="/viewer">
                <ImagesViewer />
              </RenderRoute>
            )}
            
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}
