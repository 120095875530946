import React from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";

// IMAGES
import { ReactComponent as GoogleMapsIcon } from "../../icons/googleMapsIcon.svg";


const StyledGoogleMapsButton = styled(NanoFlex)`
  height: auto;
  width: auto;
  margin-right: 8px;
  user-select: none;
  .buttonWithIconWrapper {
    width: 34px;
    height: 34px;
    cursor: pointer;
    pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
    padding: 8px;
    user-select: none;
    cursor: pointer;
    border-radius: 60px;
    .iconBtn {
      svg {
        height: 18px;
        width: auto;
      }
    }
    &:hover {
      background-color: ${(props) => props.theme.color.colorsPalette.opacityGrey};
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;

const GoogleMapsButton = (props) => {
  return (
    <StyledGoogleMapsButton className="buttonWithIcon"  disabled={props.disabled} >
      <NanoFlex className="buttonWithIconWrapper" justifyContent="flex-end" onClick={() => props.clickAction(props.id)}>
        <NanoFlex className="iconBtn">
          <GoogleMapsIcon />
        </NanoFlex>
      </NanoFlex>
    </StyledGoogleMapsButton>
  );
};

// Set default props
GoogleMapsButton.defaultProps = {
  id: 0,
  type: "submit",
  src: "",
  clickAction: () => null,
};

export default GoogleMapsButton;
