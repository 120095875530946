/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";
import { Map as LeafletMap } from "leaflet";
import { MapContainer, TileLayer, Rectangle } from "react-leaflet";

// STYLE
import "leaflet/dist/leaflet.css";

//IMGS
import { ReactComponent as Close } from "../../icons/close.svg";

const StyledMapsModal = styled(NanoFlex)`
  width: 80vw;
  height: 80vh;
  background-color: ${(props) => props.theme.color.main.white};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden;
  .googleMapsModalWrapper {
    .headerModal {
      position: relative;
      height: auto;
      padding: 16px;
      background-color: ${(props) => props.theme.color.main.yellow};
      h3 {
        text-transform: uppercase;
      }
      .closeBtn {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 6px;
        right: 6px;
        border-radius: 60px;
        cursor: pointer;
        svg {
          width: 11px;
          height: auto;
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
        &:hover {
          background-color: #ffffff8f;
        }
      }
    }
    .bodyModal {
      padding: 32px 32px;
      .locationWrapper {
        padding: 16px;
        background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
        box-sizing: border-box;

        .leaflet-container {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

const center = [39.557191, -7.8536599];

export class MapsModal extends BaseComponent {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
  }

  componentDidUpdate() {
    this.mapRef.invalidateSize();
    this.mapRef.fitBounds(this.props.location);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onCancel = () => {
    this.setState(() => {
      if (this.props.cancelAction) this.props.cancelAction();
    });
  };

  render() {
    return (
      <StyledMapsModal justifyContent="flex-start">
        <NanoFlex className="googleMapsModalWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <NanoFlex className="headerModal">
            <Type.h3>
              <b>{this.translation.global.locationMap}</b>
            </Type.h3>
            <NanoFlex className="closeBtn" onClick={this.onCancel}>
              <Close />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="bodyModal" justifyContent="flex-start">
            <NanoFlex className="locationWrapper">
              <MapContainer
                center={center}
                zoom={8}
                scrollWheelZoom={true}
                whenCreated={(map: LeafletMap) => {
                  this.mapRef = map;
                }}>
                <TileLayer attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Rectangle {...(this.props.location ? { bounds: this.props.location } : {})} pathOptions={{ color: "#105373", fillColor: "#818181"}} />
              </MapContainer>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledMapsModal>
    );
  }
}
