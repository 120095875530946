import BaseComponent from "../components/BaseComponent.js";

export class BaseService {
  
  request = (url, args) => {
    let authArgs = JSON.parse(JSON.stringify(args));
    //Prevent on FormData Uploads
    if (args.body instanceof FormData) {
      authArgs.body = args.body;
    }
    // //Set Auth Key
    // let authKey = this.getAuthorization();
    // if (authKey != null) authArgs.headers.Authorization = authKey;
    let langId = new BaseComponent().getLanguage();
    authArgs.headers.LanguageId = langId;
    return fetch(`${window.location.origin}/${url}`, authArgs);
  };
}
