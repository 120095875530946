import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";

//Images
import backgroundImage from "../../Global/images/background-image.png";

//Components
import { Footer } from "./Footer.js";
import FeedbackButton from "../../Global/Elements/Buttons/FeedbackButton.js";
import Modal from "../../Global/Elements/Modals/Modal.js";
import { FeedbackModal } from "../../Global/Elements/Modals/FeedbackModal.js";

const StyledLayout = styled(NanoFlex)`
  background-image: url(${backgroundImage});
  background-repeat: no-repeat, repeat;
  background-size: cover;
  .feedbackContainer {
    position: absolute;
    top: 0;
    right: 32px;
    height: auto;
    width: auto;
  }
`;

export class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openFeedbackModal: false,
    };
  }
  render() {
    return (
      <StyledLayout flexDirection="column" justifyContent="flex-start">
        <NanoFlex className="feedbackContainer">
          <FeedbackButton clickAction={() => this.setState({ openFeedbackModal: true })} />
          <Modal show={this.state.openFeedbackModal} content={<FeedbackModal cancelAction={() => this.setState({ openFeedbackModal: false })} nextAction={() => this.setState({ openFeedbackModal: false })} />} />
        </NanoFlex>
        {React.cloneElement(this.props.children, { render: this.props.render })}
        <Footer />
      </StyledLayout>
    );
  }
}
